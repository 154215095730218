import React from "react";
import PropTypes from "prop-types";
import { A } from "react-watty-ui";
import { Link, withRouter } from "react-router-dom";

/**
 * A link to the log in page
 */
export const LogInLink = ({ location }) => {
  return (
    <A as={Link} to={`/login?returnPath=${location.pathname}`}>
      Log In
    </A>
  );
};

LogInLink.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

export default withRouter(({ location }) => <LogInLink location={location} />);
