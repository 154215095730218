import React from "react";
import PlaceGarnish from "./PlaceGarnish";

/**
 * In illustration representing first place in a competition.
 */
const FirstPlace = props => (
  <svg
    enableBackground="new 0 0 1350 1100"
    version="1.1"
    viewBox="0 0 1350 1100"
    {...props}
  >
    <PlaceGarnish />
    <path d="m520.9 176.2c-7.5-13.7-17.8-24.6-30.8-32.8v-7.2c39-15.7 78.8-33 119.5-51.8s78.8-37.1 114.4-54.9l7.2 5.1c-2.7 45.2-4.8 89.8-6.2 133.9s-2 88.8-2 133.9v171.3c0 45.1 0.7 89.4 2 132.9s3.4 86 6.2 127.7c-8.2-1.4-16.2-2-24.1-2h-24.1-24.1c-7.9 0-15.9 0.7-24.1 2 3.4-42.4 5.6-85.2 6.7-128.2s1.5-87.2 1.5-132.3v-365.3l-122.1 67.7z" />
    <path d="m814 203.9c1.7 2 3.8 3.8 6.1 5.5 2.4 1.7 5.1 3.2 8.1 4.3s6.3 1.7 9.7 1.7c3.1 0 6.1-0.5 9-1.5s5.4-2.4 7.6-4.2 4-4.1 5.3-6.9 2-6 2-9.6c0-5.1-1.4-9-4.3-11.7s-6.4-4.9-10.7-6.6-8.9-3.2-14-4.5-9.7-3.1-14-5.5-7.8-5.5-10.7-9.4-4.3-9.3-4.3-16.2c0-5.4 0.9-10.3 2.7-14.8s4.4-8.4 7.9-11.7c3.4-3.3 7.7-5.8 12.8-7.7s10.9-2.8 17.4-2.8c6 0 11.2 0.8 15.3 2.5 4.2 1.6 8.1 3.1 11.9 4.4-1.3 2.5-2.5 5.5-3.7 9.1-1.1 3.6-1.9 6.4-2.2 8.3h-2.9c-1.3-3.9-3.8-7.4-7.6-10.5s-8.4-4.7-14-4.7c-2.5 0-4.9 0.4-7.4 1.2s-4.7 2-6.6 3.7c-2 1.6-3.6 3.8-4.9 6.4s-2 5.7-2 9.3c0 5.2 1.4 9.3 4.3 12.1 2.9 2.9 6.4 5.1 10.7 6.7s8.9 3.1 14 4.3 9.7 2.9 14 5.2c4.3 2.2 7.8 5.3 10.7 9.2s4.3 9.3 4.3 16.2c0 4.7-1 9.4-3.1 14-2 4.6-5 8.7-8.8 12.4s-8.5 6.5-13.9 8.6c-5.4 2-11.5 3.1-18.4 3.1-7.5 0-13.8-0.7-18.9-2.2s-9.3-3.2-12.8-5.2c1-2.1 2-5 3.1-8.7s1.6-6.7 1.6-9.2h2.9c0.9 1.6 2.1 3.4 3.8 5.4z" />
    <path d="m929.2 71.9c-0.5 6.2-0.9 12.3-1.1 18.3s-0.5 12-0.9 18c1.8 0 5.2 0 10.1-0.1s10.2-0.3 15.9-0.6c-0.2 0.5-0.2 1.4-0.2 2.7v5.2c0 1.1 0.1 2 0.2 2.5-2.1-0.2-4.3-0.3-6.6-0.4s-4.7-0.1-7.4-0.1h-12c-0.2 0.7-0.4 3.3-0.6 7.9s-0.5 10-0.6 16.3c-0.2 6.3-0.3 12.9-0.4 19.8s-0.1 13-0.1 18.4c0 4.6 0.1 9.1 0.4 13.5s1 8.4 2.3 11.9 3.3 6.3 5.9 8.5c2.6 2.1 6.4 3.2 11.3 3.2 1.3 0 3.1-0.2 5.3-0.5s4.1-0.7 5.8-1.2v5.9c-2.8 0.5-5.9 1.1-9.2 1.8-3.4 0.7-6.6 1.1-9.7 1.1-10.3 0-17.9-2.5-22.8-7.6s-7.4-12.5-7.4-22.3c0-4.4 0.1-9.2 0.2-14.4 0.2-5.2 0.4-10.8 0.6-16.9s0.4-12.9 0.6-20.4c0.2-7.4 0.2-15.7 0.2-24.9h-7.9c-1.3 0-2.7 0-4.2 0.1s-3 0.2-4.7 0.4c0.2-1 0.3-1.9 0.4-2.8s0.1-1.8 0.1-2.8c0-0.8 0-1.6-0.1-2.5-0.1-0.8-0.2-1.6-0.4-2.5 7.4 0.5 12.8 0.7 16.2 0.7-0.2-3.3-0.3-7.4-0.4-12.3s-0.3-10-0.6-15.2l20.4-8.6h1.4z" />
    <path d="m349.6 981.2c0-11.1-0.2-22-0.6-32.7s-0.9-21.6-1.4-32.7c4 0.3 8.1 0.6 12.2 0.9s8.2 0.4 12.2 0.4c2 0 4.5-0.1 7.3-0.3 2.9-0.2 5.8-0.3 8.7-0.4s5.8-0.2 8.5-0.4 5-0.3 6.8-0.3c2.5 0 5.6 0.1 9.3 0.3s7.6 0.7 11.7 1.5 8.2 2.1 12.2 3.8 7.7 4.1 11 7.3 5.9 7.2 8 12c2 4.8 3 10.7 3 17.8 0 11.1-2.4 20.1-7.2 27s-10.7 12.3-17.7 16.2-14.4 6.5-22.2 7.8-14.8 2-20.8 2c-3.9 0-7.4 0-10.6-0.1s-6.5-0.3-9.8-0.6v13.9c0 11.1 0.2 22 0.6 32.8s0.9 21.4 1.4 31.8c-3.9-0.3-8-0.5-12.4-0.5s-8.5 0.2-12.4 0.5c0.5-10.4 1-21 1.4-31.8s0.6-21.7 0.6-32.8v-43.4zm20.7 18.9c5.7 1.3 11.7 2 17.9 2 5.2 0 10.6-0.6 16.2-1.8s10.6-3.3 15.3-6.4c4.6-3.1 8.4-7.4 11.4-12.9 2.9-5.5 4.4-12.5 4.4-21.1 0-7.2-1-13.1-3-17.7s-4.8-8.1-8.5-10.6c-3.6-2.5-8-4.2-13.2-5.2-5.2-0.9-11.1-1.4-17.7-1.4h-22.7v75.1z" />
    <path d="m489.3 948.5c-0.4-10.7-0.9-21.6-1.4-32.7 2.2 0.3 4.3 0.6 6.3 0.9s4 0.4 6.1 0.4 4-0.1 6.1-0.4c2-0.3 4.1-0.5 6.3-0.9-0.5 11.1-1 22-1.4 32.7s-0.6 21.6-0.6 32.7c0 21.2 0.1 39.6 0.3 55.1 0.2 15.6 0.3 29.5 0.5 41.8 11.9 0 23.4-0.1 34.3-0.4s22.7-1.2 35.3-2.9c-0.8 3-1.3 5.6-1.3 7.6 0 1.9 0.4 4.1 1.3 6.8-7.7-0.3-15.5-0.5-23.2-0.5h-23.2-23.3c-7.5 0-15.3 0.2-23.3 0.5 0.5-10.4 1-21 1.4-31.8s0.6-21.7 0.6-32.8v-43.4c-0.2-11.1-0.4-22-0.8-32.7z" />
    <path d="m755.4 1089.2c-2.4-0.3-4.6-0.5-6.7-0.5h-6.7-6.7c-2.1 0-4.3 0.2-6.7 0.5-1.2-3.9-2.8-8.5-4.8-14s-4.2-11.2-6.7-17.2c-2.4-6-4.9-12-7.4-18.2-2.5-6.1-4.9-12-7.1-17.5-5.4-0.2-10.9-0.3-16.4-0.4-5.6-0.1-11.1-0.1-16.7-0.1-5.2 0-10.3 0-15.4 0.1-5 0.1-10.2 0.2-15.4 0.4-5.4 13.1-10.3 25.6-14.6 37.5-4.4 11.9-7.7 21.7-9.8 29.4-2.9-0.3-6-0.5-9.3-0.5-3.4 0-6.5 0.2-9.3 0.5 13.6-29.4 26.9-58.7 39.9-87.8s26-58.7 39.1-88.8h4.8c4 9.8 8.4 20.3 13.1 31.7s9.5 22.8 14.4 34.5c4.9 11.6 9.8 23.1 14.6 34.6 4.9 11.4 9.5 22.1 13.8 31.9s8.2 18.6 11.6 26.4c3.4 7.6 6.3 13.5 8.4 17.5zm-111.8-78.5c4.4 0 8.9 0.1 13.6 0.3s9.3 0.3 13.6 0.3c4.7 0 9.4 0 14.1-0.1s9.4-0.2 14.1-0.4l-28-64.1-27.4 64z" />
    <path d="m900.2 1083.3c-3.3 1.6-7.2 3-11.9 4.3-4.6 1.3-9.8 2.4-15.5 3.4s-11.7 1.5-17.9 1.5c-13.3 0-25.6-1.9-36.8-5.7-11.3-3.8-21-9.5-29.2-17.3-8.2-7.7-14.6-17.2-19.2-28.4s-6.9-23.9-6.9-38.2 2.3-27 6.9-38.2 11.1-20.7 19.4-28.4 18.3-13.6 29.9-17.7 24.4-6.1 38.4-6.1c11.1 0 21.2 1.1 30.4 3.4s17.3 5 24.4 8.2c-1.3 2.4-2.4 5.6-3.3 9.6-0.8 4-1.5 7.4-2 10.1l-1.5 0.5c-1.7-2-4-4.3-7.1-6.8-3-2.5-6.6-5-10.6-7.3-4-2.4-8.6-4.3-13.6-5.9s-10.6-2.4-16.7-2.4c-9.9 0-19.1 1.6-27.6 4.8s-15.9 8.1-22.2 14.8c-6.3 6.6-11.2 15.1-14.8 25.2-3.5 10.2-5.3 22.3-5.3 36.2 0 14 1.8 26 5.3 36.2s8.5 18.6 14.8 25.2 13.7 11.6 22.2 14.8 17.7 4.8 27.6 4.8c6.1 0 11.6-0.8 16.8-2.3 5.1-1.5 9.8-3.3 14.1-5.4s8.2-4.4 11.6-6.8 6.3-4.6 8.7-6.4l1 1-2.3 14.9c-1.4 1.3-3.8 2.8-7.1 4.4z" />
    <path d="m944.6 948.1c-0.4-10.8-0.9-21.5-1.4-32.3 7.6 0.3 15.1 0.6 22.6 0.9s15 0.4 22.6 0.4 15.1-0.1 22.5-0.4 14.9-0.5 22.5-0.9c-0.5 2.5-0.8 5-0.8 7.6 0 2.5 0.3 5 0.8 7.6-5.4-0.3-10.3-0.7-14.8-1s-9.2-0.7-14.1-1c-5-0.3-10.4-0.6-16.3-0.9s-13-0.4-21.2-0.4c-0.2 5.4-0.4 10.5-0.6 15.4-0.3 4.9-0.4 10.4-0.4 16.7 0 6.6 0 13 0.1 19.2s0.1 10.9 0.1 14.1c7.4 0 14-0.1 19.9-0.3s11.3-0.4 16.2-0.6c4.9-0.3 9.4-0.5 13.5-0.8s8-0.5 11.7-0.9c-0.5 2.5-0.8 5.1-0.8 7.8 0 2.5 0.3 5 0.8 7.6-4.4-0.5-9.5-0.9-15.3-1.3-5.8-0.3-11.6-0.5-17.4-0.6s-11.3-0.2-16.4-0.3-9.2-0.1-12.2-0.1c0 1.5 0 3.2-0.1 4.9-0.1 1.8-0.1 4-0.1 6.7v24.9c0 9.6 0 17.5 0.1 23.7s0.2 10.9 0.4 14.1h17.7c6.4 0 12.7-0.1 18.8-0.4s11.9-0.6 17.4-1 10.2-1 14.3-1.9c-0.3 1.2-0.5 2.5-0.6 3.9s-0.1 2.7-0.1 3.9c0 2.7 0.3 4.9 0.8 6.6-7.6-0.3-15.1-0.5-22.6-0.5h-22.8-23s-15.2 0.2-23 0.5c0.5-10.8 1-21.5 1.4-32.2s0.6-21.6 0.6-32.7v-43.4c-0.2-10.9-0.4-21.8-0.8-32.6z" />
  </svg>
);

export default FirstPlace;
