import { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CREATE_SCORE } from "../redux/actions";

/**
 * A container for creating new scores.
 */
export const ScoreCreateContainer = ({ onSave, children }) => {
  const [savedScore, setSavedScore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function handleSave(score) {
    setIsLoading(true);
    const savedScore = await onSave(score).catch(e => {
      setIsLoading(false);
      return Promise.reject(e);
    });
    setIsLoading(false);
    setSavedScore(savedScore);
  }

  return children({
    saveScore: handleSave,
    isLoading,
    savedScore
  });
};

ScoreCreateContainer.propTypes = {
  /** The method called when saving a new score. Provided by redux */
  onSave: PropTypes.func.isRequired,
  /** A function provided with data for rendering child components */
  children: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  onSave: CREATE_SCORE
};

export default connect(
  () => ({}),
  mapDispatchToProps
)(ScoreCreateContainer);
