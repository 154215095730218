import React from "react";
import PropTypes from "prop-types";

/**
 * A component for combining containers together for easier use and testing.
 *
 * Example:
<CombineContainers
  containers={[
    {
      container: UserContainer,
      reference: 'userData'
    },
    {
      container: TeamContainer,
      reference: 'teamData',
      props: {
        teamId: 'abc-123'
      }
    }
  ]}
>
  {containers => (
    // containers = { userData: {}, teamData: {} }
  )}
</CombineContainers>
 */
const CombineContainers = function({ containers, accumulatedData, children }) {
  const topContainer = containers[0];
  const remainingContainers = containers.filter((_, index) => index !== 0);
  return (
    <topContainer.component {...(topContainer.props || {})}>
      {data => {
        const returnData = {
          ...(accumulatedData || {}),
          [topContainer.reference]: data
        };
        if (!remainingContainers.length) {
          return children(returnData);
        }
        return (
          <CombineContainers
            containers={remainingContainers}
            accumulatedData={returnData}
          >
            {children}
          </CombineContainers>
        );
      }}
    </topContainer.component>
  );
};

CombineContainers.propTypes = {
  /**
   * An array of containers to be combined
   */
  containers: PropTypes.arrayOf(
    PropTypes.shape({
      /** The actual container component to be rendered */
      component: PropTypes.any,
      /** Props to be provided to the container component */
      props: PropTypes.object,
      /** A string to index the container's data under */
      reference: PropTypes.string.isRequired
    })
  ),
  /** Called with the accumulated container data */
  children: PropTypes.func.isRequired,
  /** Used internally to pass data through recursive steps */
  accumulatedData: PropTypes.object
};

export default CombineContainers;
