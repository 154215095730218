import PropTypes from "prop-types";
import { computed, createModel } from "manikin-model";
import { computeKey, computeJson, computePayload } from "./util";

/**
 * A find request for fetching Scores
 */
const ScoreFindRequest = createModel("ScoreFindRequest", {
  competitionIdList: [],
  competitionIdNotList: [],
  limit: 100,
  skip: 0,
  orderBy: "score",
  orderDirection: "descending",
  include: [],
  key: computed(function() {
    return computeKey.call(this);
  }),
  json: computed(function() {
    return computeJson.call(
      this,
      "competitionIdList",
      "competitionIdNotList",
      "limit",
      "skip",
      "orderBy",
      "orderDirection",
      "include"
    );
  }),
  payload: computed(function() {
    return computePayload.call(this);
  })
});

ScoreFindRequest.propTypes = {
  // A list of competition ids to include scores for
  competitionIdList: PropTypes.arrayOf(PropTypes.string),
  // A list of competition ids to exclude scores for
  competitionIdNotList: PropTypes.arrayOf(PropTypes.string),
  // The maximum number of records to return in one request
  limit: PropTypes.number,
  // The number of records to skip in the request; for pagination
  skip: PropTypes.number,
  // The property to sort the events by
  orderBy: PropTypes.oneOf([
    "id",
    "competitionId",
    "score",
    "invalidType",
    "status"
  ]),
  // The direction in which to sort the events
  orderDirection: PropTypes.oneOf(["ascending", "descending"]),
  // Data to be sideloaded
  include: PropTypes.arrayOf(PropTypes.oneOf(["competitors", "team"])),
  // A unique key that can be used to reference this request for cacheing
  key: PropTypes.string,
  /**
   * The find request details condensed to raw json.
   */
  json: PropTypes.shape({
    // include, competitionIdList and competitionIdNotList should be converted to comma
    // separated values
    include: PropTypes.string,
    competitionIdList: PropTypes.string,
    competitionIdNotList: PropTypes.string
  }),
  // The payload to be used to communicate with the server. Same as the `json`
  // property but with empty values filtered out.
  payload: PropTypes.shape({
    // include, competitionIdList and competitionIdNotList should be converted to comma
    // separated values
    include: PropTypes.string,
    competitionIdList: PropTypes.string,
    competitionIdNotList: PropTypes.string
  })
};

export default ScoreFindRequest;
