import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCompetitor } from "../redux/modules/competitors";
import Competitor from "../models/Competitor";
import { GET_COMPETITOR } from "../redux/actions";

/**
 * A container that fetches and provides data about a single competitor
 */
export class CompetitorContainer extends React.Component {
  static propTypes = {
    /** A function called with data about the competitor */
    children: PropTypes.func.isRequired,
    /** A function to call to fetch the competitor from the API. Provided by Redux */
    onFetch: PropTypes.func.isRequired,
    /** The relevant competitor. Provided by Redux. */
    competitor: PropTypes.instanceOf(Competitor),
    /** The ID of the competitor to display */
    competitorId: PropTypes.string.isRequired
  };

  state = {
    isLoading: true,
    errorCode: null,
    errorMessage: null
  };

  componentDidMount() {
    // Fetch the competitor if we don't have it when mounting
    if (!this.props.competitor) {
      this.fetchCompetitor();
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate(prevProps) {
    // If we change the competitor id and don't have that competitor, fetch it
    if (
      prevProps.competitorId !== this.props.competitorId &&
      !this.props.competitor
    ) {
      this.fetchCompetitor();
    }
  }

  /**
   * Fetches the competitor and updates state's isLoading, errorCode, and errorMessage.
   */
  fetchCompetitor = async () => {
    this.setState({
      isLoading: true,
      errorCode: null,
      errorMessage: null
    });
    await this.props.onFetch(this.props.competitorId).catch(e => {
      this.setState({
        errorCode: e.status,
        errorMessage: e.data.error
      });
    });
    this.setState({ isLoading: false });
  };

  render() {
    return this.props.children({
      competitor: this.props.competitor,
      errorCode: this.state.errorCode,
      errorMessage: this.state.errorMessage,
      isLoading: this.state.isLoading,
      refresh: this.fetchCompetitor
    });
  }
}

const mapStateToProps = (state, props) => ({
  competitor: getCompetitor(state, props.competitorId)
});

const mapDispatchToProps = {
  onFetch: GET_COMPETITOR
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompetitorContainer);
