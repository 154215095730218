import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  BlockListItem,
  TextButton,
  Icon,
  Input,
  Dropdown,
  Spacer
} from "react-watty-ui";
import CompetitorFindRequest from "../../models/findRequests/CompetitorFindRequest";
import CompetitorContainer from "../../containers/CompetitorContainer";
import CompetitorsFieldDropdown from "./CompetitorsFieldDropdown";
import NewCompetitorModal from "./NewCompetitorModal";

/**
 * A form field for selecting one or more competitors.
 */
const CompetitorsField = ({
  competitorIds,
  onChange,
  searchDebounce,
  blurDebounce,
  ...props
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const findRequest = new CompetitorFindRequest({
    idNotList: competitorIds,
    nameContains: debouncedSearch,
    limit: 5
  });
  let closeDropdownTimeout;
  let searchTimeout;
  return (
    <React.Fragment>
      {competitorIds.map(competitorId => (
        <CompetitorContainer key={competitorId} competitorId={competitorId}>
          {({ competitor }) => (
            <BlockListItem>
              <TextButton
                as={Icon}
                data-test-id="deselect-competitor"
                icon="times"
                onClick={() => {
                  onChange(competitorIds.filter(id => id !== competitorId));
                }}
              />{" "}
              {competitor.get("name")} (akaId: {competitor.get("akaMemberId")})
            </BlockListItem>
          )}
        </CompetitorContainer>
      ))}
      <Spacer bottom="sm" as="div" />
      <NewCompetitorModal
        competitorName={searchValue}
        onCreate={competitor => {
          onChange(competitorIds.concat([competitor.get("id")]));
          setSearchValue("");
          setDebouncedSearch("");
        }}
      >
        {({ open }) => (
          <Dropdown
            as="div"
            align="left"
            dropdownContent={({ components }) => (
              <CompetitorsFieldDropdown
                findRequest={findRequest}
                dropdownComponents={components}
                onChange={competitorId => {
                  onChange(competitorIds.concat([competitorId]));
                  setSearchValue("");
                  setDebouncedSearch("");
                }}
                onAddNew={open}
              />
            )}
          >
            {dropdown => (
              <Input
                data-test-id="search"
                onChange={e =>
                  new Promise(resolve => {
                    const value = e.target.value;
                    setSearchValue(value);
                    if (searchTimeout) {
                      window.clearTimeout(searchTimeout);
                    }
                    searchTimeout = window.setTimeout(() => {
                      setDebouncedSearch(value);
                      resolve();
                    }, searchDebounce);
                  })
                }
                value={searchValue}
                placeholder="Type to search for competitors"
                onFocus={() => {
                  if (closeDropdownTimeout) {
                    window.clearTimeout(closeDropdownTimeout);
                  }
                  dropdown.actions.open();
                }}
                onBlur={() =>
                  new Promise(resolve => {
                    closeDropdownTimeout = window.setTimeout(() => {
                      dropdown.actions.close();
                      resolve();
                    }, blurDebounce);
                  })
                }
                {...props}
              />
            )}
          </Dropdown>
        )}
      </NewCompetitorModal>
    </React.Fragment>
  );
};

CompetitorsField.defaultProps = {
  searchDebounce: 300,
  blurDebounce: 200
};

CompetitorsField.propTypes = {
  /** An array of selected competitor ids */
  competitorIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** A function called with the updated list of competitor ids when changed */
  onChange: PropTypes.func.isRequired,
  /** The amount of time to debounce search requests. Mainly used to speed up tests */
  searchDebounce: PropTypes.number,
  /** The amount of time to debounce closing the dropdown on blur. Mostly used to speed up tests. */
  blurDebounce: PropTypes.number
};

export default CompetitorsField;
