import React from "react";
import { H1 } from "react-watty-ui";
import { Redirect } from "react-router-dom";
import CombineContainers from "../../containers/CombineContainers";
import EventCreateContainer from "../../containers/EventCreateContainer";
import UserContainer from "../../containers/UserContainer";
import ErrorPage from "../ErrorPage";
import PageWrapper from "../PageWrapper";
import NewEventForm from "./NewEventForm";

/**
 * The page wrapper for the events/new page.
 */
const NewEvent = () => (
  <CombineContainers
    containers={[
      {
        component: UserContainer,
        reference: "userData"
      },
      {
        component: EventCreateContainer,
        reference: "createData"
      }
    ]}
  >
    {({ userData, createData }) => {
      // Redirect to login if the user is not logged in.
      if (!userData.isLoggedIn) {
        return <Redirect to={`/login?returnPath=/events/new`} />;
      }

      // Show 403 if the user is not an admin.
      if (!userData.user.get("isAdmin")) {
        return (
          <ErrorPage
            errorCode={403}
            errorMessage="You don't have permission to view this page."
          />
        );
      }
      return (
        <PageWrapper>
          <H1>New Event</H1>
          <NewEventForm
            onSubmit={createData.saveEvent}
            isDisabled={createData.isLoading}
          />
          {createData.savedEvent && (
            <Redirect to={`/events/${createData.savedEvent.get("id")}`} />
          )}
        </PageWrapper>
      );
    }}
  </CombineContainers>
);

export default NewEvent;
