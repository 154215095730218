import React from "react";
import PropTypes from "prop-types";
import { Spacer, P, H1, Loading, Button, ModalConfirm } from "react-watty-ui";
import styled from "styled-components";
import Event from "../../models/Event";

const InlineBlock = styled.span`
  display: inline-block;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * The heading content for the Event page, showing basic event information.
 */
const EventHeading = ({
  event,
  isLoading,
  canDelete,
  isDeleting,
  onDelete,
  isAdmin
}) => (
  <ModalConfirm
    onConfirm={onDelete}
    message="Are you sure you want to delete this event?"
  >
    {modalConfirm => {
      if (isLoading) {
        return <Loading />;
      }
      return (
        <React.Fragment>
          <Flex>
            <Spacer as={H1} right="md" data-test-id="content">
              {event.get("name")}
            </Spacer>
            {isAdmin && (
              <Button
                data-test-id="delete"
                disabled={isDeleting || !canDelete}
                onClick={canDelete ? modalConfirm.actions.open : () => {}}
                title={
                  canDelete
                    ? `Delete ${event.get("name")}`
                    : `Cannot delete the event because it contains one or more competitions.`
                }
              >
                Delete
              </Button>
            )}
          </Flex>
          <Spacer bottom="sm" as="div" />
          <P>
            <InlineBlock>
              <span data-test-id="start-date">
                {event.get("startDateMoment").format("MMM D, YYYY")}
              </span>{" "}
              -{" "}
              <span data-test-id="end-date">
                {event.get("endDateMoment").format("MMM D, YYYY")}
              </span>
            </InlineBlock>{" "}
            |{" "}
            <InlineBlock>
              {event.get("city")}, {event.get("stateShort")}
            </InlineBlock>{" "}
            | <InlineBlock>Region {event.get("region")}</InlineBlock>
          </P>
          <Spacer bottom="md" as="div" />
          <P>Description: {event.get("description")}</P>
        </React.Fragment>
      );
    }}
  </ModalConfirm>
);

EventHeading.propTypes = {
  /** The event to display */
  event: PropTypes.instanceOf(Event),
  /** Indication if the event is still loading */
  isLoading: PropTypes.bool,
  /** Can the current user delete this event? */
  canDelete: PropTypes.bool.isRequired,
  /** Are we currently deleting this event? */
  isDeleting: PropTypes.bool.isRequired,
  /** Called when the user clicks to delete the event */
  onDelete: PropTypes.func.isRequired,
  /** Is the current user an admin? */
  isAdmin: PropTypes.bool
};

export default EventHeading;
