import PropTypes from "prop-types";
import { createModel, computed } from "manikin-model";

/**
 * A user logged in from WildApricot.
 */
const User = createModel("User", {
  firstName: null,
  lastName: null,
  region: null,
  memberId: null,
  role: "user",
  isAdmin: computed(function() {
    return this.get("role") === "admin";
  })
});

User.prototype.propTypes = {
  /** The user's first name */
  firstName: PropTypes.string.isRequired,
  /** The user's last name */
  lastName: PropTypes.string.isRequired,
  /** The AKA region that the user belongs to. A numeric string */
  region: PropTypes.string,
  /**
   * The role of the user relating to permissions within sport.kite.org.
   * One of: admin, user
   */
  role: PropTypes.string,
  /** Indicates if the user is an admin */
  isAdmin: PropTypes.bool,
  /** The user's AKA member id. A numeric string */
  memberId: PropTypes.string
};

export default User;

export function getMockUser(overrides = {}) {
  return new User({
    firstName: "Frank",
    lastName: "Sinatra",
    ...overrides
  });
}
