import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getScore } from "../redux/modules/scores";
import Score from "../models/Score";
import { DELETE_SCORE, GET_SCORE } from "../redux/actions";

/**
 * A container that fetches and provides data about a single score
 */
export class ScoreContainer extends React.Component {
  static propTypes = {
    /** A function called with data about the score */
    children: PropTypes.func.isRequired,
    /** A function to call to fetch the score from the API. Provided by Redux */
    onFetch: PropTypes.func.isRequired,
    /** The relevant score. Provided by Redux. */
    score: PropTypes.instanceOf(Score),
    /** The ID of the score to display */
    scoreId: PropTypes.string.isRequired,
    /** A function to call to delete the current score. Provided by Redux. */
    onDelete: PropTypes.func.isRequired
  };

  state = {
    isLoading: true,
    errorCode: null,
    errorMessage: null,
    isDeleting: false,
    isDeleted: false
  };

  componentDidMount() {
    // Fetch the score if we don't have it when mounting
    if (!this.props.score) {
      this.fetchScore();
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate(prevProps) {
    // If we change the score id and don't have that score, fetch it
    if (prevProps.scoreId !== this.props.scoreId && !this.props.score) {
      this.fetchScore();
    }
  }

  /**
   * Fetches the score and updates state's isLoading, errorCode, and errorMessage.
   */
  fetchScore = async () => {
    this.setState({
      isLoading: true,
      errorCode: null,
      errorMessage: null
    });
    await this.props.onFetch(this.props.scoreId).catch(e => {
      this.setState({
        errorCode: e.status,
        errorMessage: e.data.error
      });
    });
    this.setState({ isLoading: false });
  };

  /**
   * Deletes the current score
   */
  deleteScore = async () => {
    this.setState({
      isDeleting: true
    });
    await this.props.onDelete(this.props.score).catch(e => {
      this.setState({
        isDeleting: false
      });
      return Promise.reject(e);
    });
    this.setState({
      isDeleting: false,
      isDeleted: true
    });
  };

  render() {
    return this.props.children({
      score: this.props.score,
      errorCode: this.state.errorCode,
      errorMessage: this.state.errorMessage,
      isLoading: this.state.isLoading,
      refresh: this.fetchScore,
      delete: this.deleteScore,
      isDeleting: this.state.isDeleting,
      isDeleted: this.state.isDeleted
    });
  }
}

const mapStateToProps = (state, props) => ({
  score: getScore(state, props.scoreId)
});

const mapDispatchToProps = {
  onFetch: GET_SCORE,
  onDelete: DELETE_SCORE
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScoreContainer);
