import showError from "../../utils/showError";
import { transformEvent } from "../../models/Event";

/**
 * Fetches an event by ID
 * @param  {String} eventId The ID of the event to fetch
 * @return {Promise<Event>}
 */
export default async function getEvent(eventId) {
  const response = await this.axiosInstance
    .get(`/events/${eventId}`)
    .catch(e => {
      showError(e.response.data.error);
      return Promise.reject(e.response);
    });

  return transformEvent(response.data);
}
