import React from "react";
import PropTypes from "prop-types";
import {
  P,
  Spacer,
  Table,
  TableRow,
  TableHeading,
  TableCell
} from "react-watty-ui";
import Score from "../../models/Score";
import Competitor from "../../models/Competitor";
import Team from "../../models/Team";
import determineRankings from "../../utils/determineRankings";
import UserContainer from "../../containers/UserContainer";
import DeleteScoreButton from "./DeleteScoreButton";

const ScoresDisplay = ({ scores, competitors, teams }) => {
  const rankedScores = determineRankings(scores);
  let ties = 0;
  return (
    <UserContainer>
      {({ isAdmin }) => (
        <Table>
          <thead>
            <TableRow>
              <TableHeading>Place</TableHeading>
              <TableHeading>Score</TableHeading>
              <TableHeading>Competitor(s)</TableHeading>
              {isAdmin && <TableHeading data-test-id="admin-heading" />}
            </TableRow>
          </thead>
          <tbody>
            {rankedScores.map((ranking, index) => {
              const _ties = ties;
              ties += ranking.length - 1;
              if (ranking[0].get("isInvalid")) {
                return (
                  <TableRow key={ranking[0].get("id")}>
                    <TableHeading data-test-id="rank">DQ</TableHeading>
                    <TableCell>--</TableCell>
                    <TableCell>
                      {ranking.map(score => {
                        const relevantTeam = score.get("teamId")
                          ? teams[score.get("teamId")]
                          : null;
                        const relevantCompetitors = score
                          .get("competitorIds")
                          .map(id => competitors[id]);
                        const competitorNames = relevantCompetitors
                          .map(comp => comp.get("name"))
                          .join(", ");
                        return (
                          <Spacer bottom="md" key={score.get("id")}>
                            <P>
                              {relevantTeam
                                ? relevantTeam.get("name")
                                : competitorNames}
                            </P>
                            {relevantTeam && <P>{competitorNames}</P>}
                            <P>{score.get("invalidType")}</P>
                          </Spacer>
                        );
                      })}
                    </TableCell>
                  </TableRow>
                );
              }
              return (
                <TableRow key={ranking[0].get("id")}>
                  <TableHeading data-test-id="rank">
                    {index + 1 + _ties}.
                  </TableHeading>
                  <TableCell>{ranking[0].get("score")}</TableCell>
                  <TableCell>
                    {ranking.map((score, scoreIndex) => {
                      const relevantTeam = score.get("teamId")
                        ? teams[score.get("teamId")]
                        : null;
                      const relevantCompetitors = score
                        .get("competitorIds")
                        .map(id => competitors[id]);
                      const competitorNames = relevantCompetitors
                        .map(comp => comp.get("name"))
                        .join(", ");
                      return (
                        <Spacer
                          as="div"
                          top={scoreIndex > 0 ? "md" : null}
                          key={score.get("id")}
                        >
                          <P>
                            {relevantTeam
                              ? relevantTeam.get("name")
                              : competitorNames}
                          </P>
                          {relevantTeam && (
                            <P>
                              <i>
                                <small>{competitorNames}</small>
                              </i>
                            </P>
                          )}
                        </Spacer>
                      );
                    })}
                  </TableCell>
                  {isAdmin && (
                    <TableCell data-test-id="admin-cell">
                      {ranking.map(score => (
                        <Spacer key={score.get("id")} as="div">
                          <DeleteScoreButton score={score} />
                        </Spacer>
                      ))}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </tbody>
        </Table>
      )}
    </UserContainer>
  );
};

ScoresDisplay.propTypes = {
  /** The scores to display */
  scores: PropTypes.arrayOf(PropTypes.instanceOf(Score)),
  /** The competitors related to the scores, indexed by id */
  competitors: PropTypes.objectOf(PropTypes.instanceOf(Competitor)),
  /** The teams related to the scores, indexed by id */
  teams: PropTypes.objectOf(PropTypes.instanceOf(Team))
};

export default ScoresDisplay;
