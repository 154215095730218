/**
 * Utility functions used for computed properties in find requests
 */

// Generates a string key as reference to a find request for cacheing
export const computeKey = function() {
  const json = this.get("json");
  const keys = Object.keys(json);
  keys.sort();
  const values = keys.map(key => json[key]);
  return values.join("_");
};

// Condenses a find request to pure json
export const computeJson = function(...values) {
  const json = this.getProperties(...values);
  Object.keys(json).forEach(key => {
    const value = json[key];
    if (Array.isArray(value)) {
      json[key] = value.join(",");
    }
  });
  return json;
};

// Prepares a payload for the find request based on computeJson
export const computePayload = function() {
  const json = this.get("json");
  Object.keys(json).forEach(key => {
    const value = json[key];
    if (value === null || value === "") {
      delete json[key];
    }
  });
  return json;
};
