import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { Loading, Spacer } from "react-watty-ui";
import EventPageContainer from "../../containers/pages/EventPageContainer";
import ErrorPage from "../ErrorPage";
import PageWrapper from "../PageWrapper";
import EventHeading from "./EventHeading";
import EventCompetitions from "./EventCompetitions";

/**
 * Top level layout component for the Event page.
 */
const Event = ({ match }) => {
  const eventId = match.params.eventId;
  return (
    <EventPageContainer eventId={eventId}>
      {({ eventData, competitionsData, userData }) => {
        const isLoading = eventData.isLoading && competitionsData.isLoading;
        if (eventData.isDeleted) {
          return <Redirect to="/events" />;
        }
        if (isLoading) {
          return (
            <PageWrapper>
              <Loading />
            </PageWrapper>
          );
        }
        if (!eventData.event && !eventData.isLoading) {
          return (
            <ErrorPage
              errorCode={eventData.errorCode || 404}
              errorMessage={eventData.errorMessage || "Event not found"}
            />
          );
        }
        if (!eventData.isLoading) {
          return (
            <PageWrapper data-test-id="event-content">
              <EventHeading
                canDelete={!competitionsData.competitions.length}
                isAdmin={userData.isAdmin}
                onDelete={eventData.delete}
                isDeleting={eventData.isDeleting}
                event={eventData.event}
                isLoading={eventData.isLoading}
              />
              <Spacer as="div" bottom="lg" />
              {competitionsData.scores && (
                <EventCompetitions
                  event={eventData.event}
                  competitions={competitionsData.competitions}
                  scores={competitionsData.scores}
                  competitors={competitionsData.competitors}
                  teams={competitionsData.teams}
                  isLoading={competitionsData.isLoading}
                  isAdmin={userData.isAdmin}
                />
              )}
            </PageWrapper>
          );
        }
        return null;
      }}
    </EventPageContainer>
  );
};

Event.propTypes = {
  /** The route match data provided by Route */
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventId: PropTypes.string.isRequired
    })
  }).isRequired
};

export default Event;
