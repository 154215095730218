import React from "react";
import PropTypes from "prop-types";
import { Spacer, P, H1, Loading, A } from "react-watty-ui";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Event from "../../models/Event";
import Competition from "../../models/Competition";
import DeleteCompetitionButton from "./DeleteCompetitionButton";

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const InlineBlock = styled.span`
  display: inline-block;
`;

/**
 * The heading content for the Event page, showing basic event information.
 */
const CompetitionHeading = ({ event, competition, isLoading }) => {
  if (isLoading) {
    return <Loading />;
  }
  return (
    <React.Fragment>
      <Flex>
        <Spacer as={H1} right="md" data-test-id="content">
          {competition.get("title")}
        </Spacer>
        <DeleteCompetitionButton competition={competition} event={event} />
      </Flex>
      <P>
        at{" "}
        <A as={Link} to={`/events/${event.get("id")}`}>
          {event.get("name")}
        </A>
      </P>
      <Spacer bottom="sm" as="div" />
      <P>
        <InlineBlock>
          {competition.get("dateMoment").format("ddd MMM D, YYYY")}
        </InlineBlock>{" "}
        |{" "}
        <InlineBlock>
          {event.get("city")}, {event.get("stateShort")}
        </InlineBlock>{" "}
        | <InlineBlock>Region {event.get("region")}</InlineBlock>
      </P>
      <Spacer bottom="md" as="div" />
    </React.Fragment>
  );
};

CompetitionHeading.propTypes = {
  /** The event to display */
  event: PropTypes.instanceOf(Event),
  /** The competition to display */
  competition: PropTypes.instanceOf(Competition),
  /** Indication if the event is still loading */
  isLoading: PropTypes.bool
};

export default CompetitionHeading;
