import React from "react";
import PropTypes from "prop-types";
import { Loading, Spacer, P } from "react-watty-ui";
import TeamFindRequest from "../../models/findRequests/TeamFindRequest";
import TeamsContainer from "../../containers/TeamsContainer";

/**
 * The search results dropdown content for the TeamField.
 */
const TeamFieldDropdown = ({
  findRequest,
  dropdownComponents,
  onChange,
  onAddNew
}) => (
  <TeamsContainer findRequest={findRequest}>
    {({ teams, isLoading }) => {
      if (isLoading) {
        return <Loading />;
      }
      if (!teams.length) {
        return (
          <React.Fragment>
            <Spacer
              data-test-id="empty-state"
              as={P}
              top="sm"
              left="sm"
              bottom="sm"
              right="sm"
            >
              No teams match your search.
            </Spacer>
            <dropdownComponents.Item onClick={onAddNew}>
              Create New Team
            </dropdownComponents.Item>
          </React.Fragment>
        );
      }
      return teams.map(team => (
        <dropdownComponents.Item
          key={team.get("id")}
          data-test-id="team-option"
          onClick={() => onChange(team.get("id"))}
        >
          {team.get("name")}
        </dropdownComponents.Item>
      ));
    }}
  </TeamsContainer>
);

TeamFieldDropdown.propTypes = {
  /** The find request to load the teams */
  findRequest: PropTypes.instanceOf(TeamFindRequest),
  /** Components provided from Dropdown */
  dropdownComponents: PropTypes.shape({
    Item: PropTypes.any.isRequired
  }).isRequired,
  /** Called when a team is selected. Provided with the team id */
  onChange: PropTypes.func.isRequired,
  /** Called when the user wants to create a new team */
  onAddNew: PropTypes.func.isRequired
};

export default TeamFieldDropdown;
