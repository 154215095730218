import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import {
  Label,
  Input,
  Select,
  Textarea,
  DatePicker,
  Spacer,
  Button,
  Error,
  P
} from "react-watty-ui";
import { STATES, REGIONS } from "../../constants/geography";
import Event from "../../models/Event";

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ButtonWrapper = styled.div`
  text-align: right;
`;

/**
 * Determine what errors exist in the form.
 * @param  {Object} data
 * @return {Object}
 */
function getErrors(data) {
  const errors = {};
  if (!data.name) {
    errors.name = "A name must be provided.";
  }

  if (!data.startDate) {
    errors.startDate = "A start date must be provided.";
  } else if (data.endDate && data.startDate > data.endDate) {
    errors.startDate = "The start date cannot come after the end date.";
  }

  if (!data.endDate) {
    errors.endDate = "An end date must be provided.";
  } else if (data.startDate && data.startDate > data.endDate) {
    errors.endDate = "The end date cannot come before the start date.";
  }

  if (!data.city) {
    errors.city = "A city must be provided";
  }

  if (!data.state) {
    errors.state = "A state must be selected";
  }

  if (!data.region) {
    errors.region = "A region must be selected";
  }

  return errors;
}

/**
 * A form for creating a new event.
 */
const NewEventForm = ({ onSubmit, isDisabled }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [region, setRegion] = useState("");
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const errors = getErrors({
    name,
    description,
    startDate,
    endDate,
    city,
    state,
    region
  });
  const hasErrors = Object.keys(errors).length > 0;

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        if (isDisabled) {
          return;
        }
        setSubmitAttempted(true);
        if (hasErrors) {
          return;
        }
        const event = new Event({
          name,
          description,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
          city,
          state,
          region
        });
        onSubmit(event);
      }}
    >
      <Label>Name</Label>
      <Input
        data-test-id="name-field"
        value={name}
        onChange={e => setName(e.target.value)}
      />
      {submitAttempted && errors.name && (
        <Error data-test-id="name-error">
          <P>{errors.name}</P>
        </Error>
      )}
      <Spacer bottom="md" as="div" />

      <Label>Description</Label>
      <Textarea
        data-test-id="description-field"
        palceholder="Optional"
        value={description}
        onChange={e => setDescription(e.target.value)}
      />
      <Spacer bottom="md" as="div" />

      <FlexWrapper>
        <Spacer right="md" as="div">
          <Label>Start Date</Label>
          <DatePicker
            data-test-id="startDate-field"
            selected={startDate}
            onChange={date => setStartDate(date)}
          />
          {submitAttempted && errors.startDate && (
            <Error data-test-id="startDate-error">
              <P>{errors.startDate}</P>
            </Error>
          )}
          <Spacer bottom="md" as="div" />
        </Spacer>
        <div>
          <Label>End Date</Label>
          <DatePicker
            data-test-id="endDate-field"
            selected={endDate}
            onChange={date => setEndDate(date)}
          />
          {submitAttempted && errors.endDate && (
            <Error data-test-id="endDate-error">
              <P>{errors.endDate}</P>
            </Error>
          )}
          <Spacer bottom="md" as="div" />
        </div>
      </FlexWrapper>

      <FlexWrapper>
        <Spacer right="md" as="div">
          <Label>City</Label>
          <Input
            data-test-id="city-field"
            value={city}
            onChange={e => setCity(e.target.value)}
          />
          {submitAttempted && errors.city && (
            <Error data-test-id="city-error">
              <P>{errors.city}</P>
            </Error>
          )}
          <Spacer bottom="md" as="div" />
        </Spacer>
        <Spacer right="md" as="div">
          <Label>State</Label>
          <Select
            data-test-id="state-field"
            value={state}
            onChange={e => setState(e.target.value)}
          >
            <option></option>
            {STATES.map(state => (
              <option key={state.short}>{state.full}</option>
            ))}
          </Select>
          {submitAttempted && errors.state && (
            <Error data-test-id="state-error">
              <P>{errors.state}</P>
            </Error>
          )}
          <Spacer bottom="md" as="div" />
        </Spacer>
        <div>
          <Label>Region</Label>
          <Select
            data-test-id="region-field"
            value={region}
            onChange={e => setRegion(e.target.value)}
          >
            <option></option>
            {REGIONS.map(region => (
              <option key={region.name} value={region.name}>
                {region.name} - {region.description}
              </option>
            ))}
          </Select>
          {submitAttempted && errors.region && (
            <Error data-test-id="region-error">
              <P>{errors.region}</P>
            </Error>
          )}
          <Spacer bottom="md" as="div" />
        </div>
      </FlexWrapper>
      <Spacer bottom="md" as="div" />
      <ButtonWrapper>
        <Button type="submit" isPrimary disabled={isDisabled}>
          Submit
        </Button>
      </ButtonWrapper>
    </form>
  );
};

NewEventForm.defaultProps = {
  isDisabled: false
};

NewEventForm.propTypes = {
  /**
   * Called when the form is submitted. Provided with a new Event as the first
   * parameter.
   */
  onSubmit: PropTypes.func.isRequired,
  /** Should the form be disabled? */
  isDisabled: PropTypes.bool
};

export default NewEventForm;
