import React from "react";
import { Button } from "react-watty-ui";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import UserContainer from "../../containers/UserContainer";

/**
 * A link to the score creation page. Only visible to admins.
 */
const CreateScoreLink = ({ eventId, competitionId, ...props }) => (
  <UserContainer>
    {userData => {
      if (!userData.isAdmin) {
        return null;
      }
      return (
        <Button
          as={Link}
          to={`/events/${eventId}/competitions/${competitionId}/scores/new`}
          {...props}
        >
          Add Score
        </Button>
      );
    }}
  </UserContainer>
);

CreateScoreLink.propTypes = {
  /** The ID of the event to create the score under */
  eventId: PropTypes.string.isRequired,
  /** The ID of the competition to create the score under */
  competitionId: PropTypes.string.isRequired
};

export default CreateScoreLink;
