/**
 * Given the name of a query parameter, get the value from the URL
 * @param  {String} name
 * @return {String}
 */
export default function getQueryParameter(name) {
  /* eslint-disable no-useless-escape */
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}
