import showError from "../../utils/showError";
import showSuccess from "../../utils/showSuccess";

/**
 * Deletes the provided score from the server.
 * @param  {Event} score The score to be deleted. Must have an id.
 * @return {Promise<String>} The ID of the score that was deleted
 */
export default async function deleteEvent(score) {
  await this.axiosInstance.delete(`/scores/${score.get("id")}`).catch(e => {
    showError(e.response.data.error);
    return Promise.reject(e);
  });
  showSuccess("The Score has been deleted.");
  return score.get("id");
}
