import showError from "../../utils/showError";
import showSuccess from "../../utils/showSuccess";
import { transformScore } from "../../models/Score";

/**
 * Saves a Score on the server.
 * @param {Score} score A score to save
 * @return {Promise<Score>} The score returned from the server after saving
 */
export default async function createScore(score) {
  const response = await this.axiosInstance
    .post(
      "/scores",
      score.getProperties(
        "competitionId",
        "score",
        "invalidType",
        "notes",
        "status",
        "teamId",
        "competitorIds"
      )
    )
    .catch(e => {
      showError(e.response.data.error);
      return Promise.reject(e);
    });

  showSuccess("A Score has been created.");

  // Build and return the score
  return transformScore(response.data);
}
