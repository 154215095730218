import React from "react";
import PropTypes from "prop-types";
import ScoreFindRequest from "../../models/findRequests/ScoreFindRequest";
import EventContainer from "../EventContainer";
import CompetitionContainer from "../CompetitionContainer";
import ScoresContainer from "../ScoresContainer";

/**
 * Container that coordinates data for the competition page.
 */
const CompetitionPageContainer = ({ eventId, competitionId, children }) => {
  const scoreFindRequest = new ScoreFindRequest({
    competitionIdList: [competitionId],
    orderBy: "score",
    orderDirection: "descending",
    include: ["competitors", "scores", "team"]
  });
  return (
    <EventContainer eventId={eventId}>
      {eventData => (
        <CompetitionContainer competitionId={competitionId}>
          {competitionData => (
            <ScoresContainer findRequest={scoreFindRequest}>
              {scoresData =>
                children({
                  eventData,
                  competitionData,
                  scoresData
                })
              }
            </ScoresContainer>
          )}
        </CompetitionContainer>
      )}
    </EventContainer>
  );
};

CompetitionPageContainer.propTypes = {
  /** The ID of the event to get data for */
  eventId: PropTypes.string.isRequired,
  /** The ID of the competition to get data for */
  competitionId: PropTypes.string.isRequired,
  /** A function to be provided with `eventData` and `competitionsData`. */
  children: PropTypes.func.isRequired
};

export default CompetitionPageContainer;
