import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { H1, Spacer, Loading, Button } from "react-watty-ui";
import EventsPageContainer from "../../containers/pages/EventsPageContainer";
import PageWrapper from "../PageWrapper";
import EventBlock from "./EventBlock";
import EventsEmpty from "./EventsEmpty";

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * The coordinating component for the Events page.
 */
const Events = () => (
  <PageWrapper>
    <EventsPageContainer>
      {({ eventsData, userData }) => (
        <React.Fragment>
          <Flex>
            <Spacer as={H1} right="md">
              Events
            </Spacer>
            {userData.isLoggedIn && userData.user.get("isAdmin") && (
              <Button data-test-id="new-event" as={Link} to="/events/new">
                Add Event
              </Button>
            )}
          </Flex>
          <Spacer as="div" bottom="md" />
          {eventsData.isLoading && <Loading />}
          {!eventsData.isLoading && !eventsData.events.length ? (
            <EventsEmpty />
          ) : (
            eventsData.events.map(event => (
              <EventBlock event={event} key={event.get("id")} />
            ))
          )}
        </React.Fragment>
      )}
    </EventsPageContainer>
  </PageWrapper>
);

export default Events;
