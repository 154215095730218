import React from "react";
import PropTypes from "prop-types";
import { A, H1, P, Loading } from "react-watty-ui";
import { Link } from "react-router-dom";
import EventContainer from "../../containers/EventContainer";

/**
 * A header for the New Competition page that includes event information.
 */
const NewCompetitionHeading = ({ eventId }) => (
  <EventContainer eventId={eventId}>
    {({ isLoading, event }) => (
      <React.Fragment>
        <H1>New Competition</H1>
        {isLoading ? (
          <Loading />
        ) : (
          <P data-test-id="for-event">
            Creating a competition for{" "}
            <A as={Link} to={`/events/${eventId}`}>
              {event.get("name")}
            </A>
            .
          </P>
        )}
      </React.Fragment>
    )}
  </EventContainer>
);

NewCompetitionHeading.propTypes = {
  /** The ID of the event that the competiiton is being created for */
  eventId: PropTypes.string.isRequired
};

export default NewCompetitionHeading;
