import showError from "../../utils/showError";
import { transformCompetition } from "../../models/Competition";

/**
 * Fetches a competition by ID
 * @param  {String} competitionId The ID of the competition to fetch
 * @return {Promise<Competition>}
 */
export default async function getCompetition(competitionId) {
  const response = await this.axiosInstance
    .get(`/competitions/${competitionId}`)
    .catch(e => {
      showError(e.response.data.error);
      return Promise.reject(e.response);
    });

  return transformCompetition(response.data);
}
