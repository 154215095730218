import showError from "../../utils/showError";
import showSuccess from "../../utils/showSuccess";
import { transformCompetition } from "../../models/Competition";

/**
 * Saves a Competition on the server.
 * @param {Competition} competition A competition to save
 * @return {Promise<Competition>} The competition returned from the server after saving
 */
export default async function createCompetition(competition) {
  const response = await this.axiosInstance
    .post(
      "/competitions",
      competition.getProperties(
        "eventId",
        "discipline",
        "competitorType",
        "level",
        "date",
        "category",
        "scoreIds"
      )
    )
    .catch(e => {
      showError(e.response.data.error);
      return Promise.reject(e);
    });

  showSuccess("A Competition has been created.");

  // Build and return the competition
  return transformCompetition(response.data);
}
