import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalClose, H2 } from "react-watty-ui";
import TeamCreateContainer from "../../containers/TeamCreateContainer";
import NewTeamForm from "./NewTeamForm";

/**
 * A modal that can be used to create a team on the fly.
 */
const NewTeamModal = ({ teamName, children, onCreate }) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <TeamCreateContainer>
      {createData => (
        <React.Fragment>
          <Modal isOpen={modalOpen} onBackdropClick={() => setModalOpen(false)}>
            <ModalClose onClick={() => setModalOpen(false)} />

            <H2>Create Team</H2>

            <NewTeamForm
              teamName={teamName}
              onSubmit={async team => {
                const newTeam = await createData.saveTeam(team);
                setModalOpen(false);
                onCreate(newTeam);
              }}
              isDisabled={createData.isLoading}
            />
          </Modal>
          {children({
            open: () => setModalOpen(true)
          })}
        </React.Fragment>
      )}
    </TeamCreateContainer>
  );
};

NewTeamModal.propTypes = {
  /** An optional team name to preopoulate in the form */
  teamName: PropTypes.string,
  /** A function called with data for managing the modal. */
  children: PropTypes.func.isRequired,
  /** A function called when a team is successfully created */
  onCreate: PropTypes.func
};

export default NewTeamModal;
