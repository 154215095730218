import PropTypes from "prop-types";
import { computed, createModel } from "manikin-model";
import moment from "moment";
import titleCase from "../utils/titleCase";

/**
 * A cache expiration for Competitions. Any competition or list of competitions
 * that has been cached for more than this duration will be fetched the next
 * time it is requested.
 */
export const CACHE_EXPIRATION = 10 * 60 * 1000; // 10 minutes

export const DISCIPLINES = ["ballet", "precision"];

export const COMPETITOR_TYPES = ["individual", "pair", "team"];

export const LEVELS = ["novice", "experienced", "masters", "open"];

export const CATEGORIES = [
  "dual-line",
  "multi-line",
  "indoor single-line",
  "indoor unlimited",
  "unlimited"
];

/**
 * An individual competition
 */
const Competition = createModel("Competition", {
  id: null,
  eventId: null,
  title: computed(function() {
    const parts = [
      this.get("level"),
      this.get("competitorType"),
      this.get("category"),
      this.get("discipline")
    ].filter(part => !!part);
    return titleCase(parts.join(" "));
  }),
  discipline: null,
  competitorType: null,
  level: null,
  date: null,
  dateMoment: computed(function() {
    return moment(this.get("date"), "YYYY-MM-DD");
  }),
  category: null,
  scoreIds: [],
  fetchTime: null,
  isStale: computed(function() {
    return new Date().getTime() - this.get("fetchTime") > CACHE_EXPIRATION;
  })
});

Competition.prototype.propTypes = {
  /** The ID of the competition */
  id: PropTypes.string,
  /** The ID of the event that this competition belongs to */
  eventId: PropTypes.string,
  /** The discipline (ballet, precision) */
  discipline: PropTypes.string,
  /** The type of competitor (individual, pair, team) */
  competitorType: PropTypes.string,
  /* The experience level (novice, experienced, masters, open) */
  level: PropTypes.string,
  /** The date when the competition took place */
  date: PropTypes.string,
  /** `date` parsed by momentjs */
  dateMoment: PropTypes.object,
  /**
   * The competition category (
   *   indoor single-line,
   *   dual-line,
   *   multi-line,
   *   indoor unlimited,
   *   unlimited
   * )
   */
  category: PropTypes.string,
  /** Ids of the scores related to this competition */
  scoreIds: PropTypes.arrayOf(PropTypes.string),
  /** The new Date().getTime() from when the record was fetched */
  fetchTime: PropTypes.number,
  /** Indicates if the record is stale and needs to be refetched */
  isStale: PropTypes.bool
};

export default Competition;

/**
 * Transforms the raw competition data from the server into a Competition.
 * @param  {Object} rawCompetition
 * @param  {Number} [fetchTime=currentTime] when the record was retrieved (ms)
 * @return {Competition}
 */
export function transformCompetition(
  rawCompetition,
  fetchTime = new Date().getTime()
) {
  const eventId = rawCompetition.eventId;
  const hasEventId = eventId !== null && eventId !== undefined;
  return new Competition({
    id: rawCompetition.id.toString(),
    eventId: hasEventId ? eventId.toString() : null,
    discipline: rawCompetition.discipline,
    competitorType: rawCompetition.competitorType,
    level: rawCompetition.level,
    date: rawCompetition.date,
    category: rawCompetition.category,
    scoreIds: rawCompetition.scoreIds.map(id => id.toString()),
    fetchTime
  });
}

export function getMockCompetition(overrides = {}) {
  return new Competition({
    id: "123",
    eventId: "0",
    discipline: "ballet",
    competitorType: "individual",
    level: "novice",
    date: "2019-09-07",
    category: "dual-line",
    scoreIds: [],
    fetchTime: new Date().getTime(),
    ...overrides
  });
}
