import showError from "../../utils/showError";
import { transformEvent } from "../../models/Event";

/**
 * Fetches events based on the provided findRequest
 * @param  {EventFindRequest} findRequest
 * @return {Promise<Object>} Resolves to `{ events: Event[], meta: object }`
 */
export default async function getEvents(findRequest) {
  // Reduce the find request down to a simple payload
  const params = findRequest.get("payload");

  // Request the events
  const response = await this.axiosInstance
    .get("/events", {
      params
    })
    .catch(e => {
      showError(e.response.data.error);
      return Promise.reject(e.response);
    });

  // cast the events to an Event object
  const currentTime = new Date().getTime();
  const events = response.data.events.map(event => {
    return transformEvent(event, currentTime);
  });

  // Return the events and the meta data.
  return {
    events,
    meta: response.data.meta,
    findRequestKey: findRequest.get("key")
  };
}
