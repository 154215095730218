import PropTypes from "prop-types";
import { computed, createModel } from "manikin-model";

/**
 * A cache expiration for Competitors. Any competitor or list of competitors
 * that has been cached for more than this duration will be fetched the next
 * time it is requested.
 */
export const CACHE_EXPIRATION = 30 * 60 * 1000; // 30 minutes

/**
 * An individual person who competes at AKA events.
 */
const Competitor = createModel("Competitor", {
  id: null,
  name: null,
  akaMemberId: null,
  fetchTime: null,
  isStale: computed(function() {
    return new Date().getTime() - this.get("fetchTime") > CACHE_EXPIRATION;
  })
});

Competitor.prototype.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  akaMemberId: PropTypes.string.isRequired,
  /** The new Date().getTime() from when the record was fetched */
  fetchTime: PropTypes.number,
  /** Indicates if the record is stale and needs to be refetched */
  isStale: PropTypes.bool
};

export default Competitor;

/**
 * Transforms raw competitor data into a Competitor
 * @param  {Object} rawCompetitor
 * @param  {Number} [fetchTime=currentTime] when the record was retrieved (ms)
 * @return {Competitor}
 */
export function transformCompetitor(
  rawCompetitor,
  fetchTime = new Date().getTime()
) {
  return new Competitor({
    id: rawCompetitor.id.toString(),
    name: rawCompetitor.name,
    akaMemberId: rawCompetitor.akaMemberId,
    fetchTime
  });
}

export function getMockCompetitor(overrides = {}) {
  return new Competitor({
    id: "123",
    name: "Spencer Watson",
    akaMemberId: "aka123",
    fetchTime: new Date().getTime(),
    ...overrides
  });
}
