import { computed, createModel } from "manikin-model";
import PropTypes from "prop-types";

/**
 * A cache expiration for Teams. Any team or list of teams that has been cached
 * for more than this duration will be fetched the next time it is requested.
 */
export const CACHE_EXPIRATION = 30 * 60 * 1000; // 30 minutes

/**
 * A team that competes in events. A team is made up of 2 or more competitors.
 */
const Team = createModel("Team", {
  id: null,
  name: null,
  description: "",
  fetchTime: null,
  isStale: computed(function() {
    return new Date().getTime() - this.get("fetchTime") > CACHE_EXPIRATION;
  })
});

Team.prototype.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  /** The new Date().getTime() from when the record was fetched */
  fetchTime: PropTypes.number,
  /** Indicates if the record is stale and needs to be refetched */
  isStale: PropTypes.bool
};

export default Team;

/**
 * Transforms raw team data into a Team.
 * @param  {Object} rawTeam
 * @param  {Number} [fetchTime=currentTime] when the record was retrieved (ms)
 * @return {Team}
 */
export function transformTeam(rawTeam, fetchTime = new Date().getTime()) {
  return new Team({
    id: rawTeam.id.toString(),
    name: rawTeam.name,
    description: rawTeam.description,
    fetchTime
  });
}

export function getMockTeam(overrides = {}) {
  return new Team({
    id: "123",
    name: "The Kite Collective",
    description: "Best Team Ever",
    fetchTime: new Date().getTime(),
    ...overrides
  });
}
