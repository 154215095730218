import showError from "../../utils/showError";
import showSuccess from "../../utils/showSuccess";

/**
 * Deletes the provided competition from the server.
 * @param  {Event} competition The competition to be deleted. Must have an id.
 * @return {Promise<String>} The ID of the competition that was deleted
 */
export default async function deleteEvent(competition) {
  await this.axiosInstance
    .delete(`/competitions/${competition.get("id")}`)
    .catch(e => {
      showError(e.response.data.error);
      return Promise.reject(e);
    });

  showSuccess("The Competition has been deleted.");

  return competition.get("id");
}
