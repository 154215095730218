import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CompetitionFindRequest from "../models/findRequests/CompetitionFindRequest";
import { getCompetitions } from "../redux/modules/competitions";
import { getTeamsIndexedById } from "../redux/modules/teams";
import { getScoresGroupedBy } from "../redux/modules/scores";
import { getCompetitorsIndexedById } from "../redux/modules/competitors";
import Competition from "../models/Competition";
import Team from "../models/Team";
import Score from "../models/Score";
import Competitor from "../models/Competitor";
import { GET_COMPETITIONS } from "../redux/actions";

/**
 * A container that deals with requesting competitions based on the provided findRequest.
 */
export class CompetitionsContainer extends React.Component {
  static propTypes = {
    /** A function which is provided with `refresh()`, `competitions`, and `isLoading` */
    children: PropTypes.func.isRequired,
    /** A function to call when the competitions need to be fetched. Provided by redux. */
    getCompetitions: PropTypes.func.isRequired,
    /** The fetched array of Competitions. Provided by redux. */
    competitions: PropTypes.arrayOf(PropTypes.instanceOf(Competition)),
    /** The sideloaded array of teams. Provided by redux. */
    teams: PropTypes.objectOf(PropTypes.instanceOf(Team)),
    /** The sideloaded array of scores. Provided by redux. */
    scores: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.instanceOf(Score))),
    /** The sideloaded array of competitors. Provided by redux. */
    competitors: PropTypes.objectOf(PropTypes.instanceOf(Competitor)),
    /** The find request used to control the requests being made */
    findRequest: PropTypes.instanceOf(CompetitionFindRequest)
  };

  static defaultProps = {
    findRequest: new CompetitionFindRequest()
  };

  state = {
    isLoading: true
  };

  // Fetch competitions when the component is mounted if we don't already have a
  // cached request
  componentDidMount() {
    if (this.props.competitions === null) {
      this.getCompetitions();
    } else {
      this.setState({ isLoading: false });
    }
  }

  // Fetch competitions when the findRequest changs if we don't already have a
  // cached request
  componentDidUpdate(prevProps) {
    if (
      prevProps.findRequest !== this.props.findRequest &&
      this.props.competitions === null
    ) {
      this.getCompetitions();
    }
  }

  getCompetitions = async () => {
    this.setState({ isLoading: true });
    await this.props
      .getCompetitions(this.props.findRequest)
      // If the request fails, we throw a toast from the API layer. No sepcial
      // handling here.
      .catch(() => {});
    this.setState({ isLoading: false });
  };

  render() {
    return this.props.children({
      refresh: this.getCompetitions,
      competitions: this.props.competitions || [],

      // scores, teams, and competitors may be sideloaded depending on the
      // find request.
      scores: this.props.scores,
      teams: this.props.teams,
      competitors: this.props.competitors,
      isLoading: this.state.isLoading
    });
  }
}

const mapStateToProps = (state, props) => ({
  competitions: getCompetitions(state, props.findRequest),
  scores: getScoresGroupedBy(state, props.findRequest, "competitionId"),
  competitors: getCompetitorsIndexedById(state, props.findRequest),
  teams: getTeamsIndexedById(state, props.findRequest)
});

const mapDispatchToProps = {
  getCompetitions: GET_COMPETITIONS
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompetitionsContainer);
