import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Spacer,
  Label,
  Textarea,
  Input,
  Error,
  P,
  Button
} from "react-watty-ui";
import Team from "../../models/Team";

const ButtonWrapper = styled.div`
  text-align: right;
`;

function getErrors(data) {
  const errors = {};
  if (!data.name) {
    errors.name = "A name must be provided.";
  }

  return errors;
}

/**
 * A form for creating a new Team
 */
const NewTeamForm = ({ teamName, onSubmit, isDisabled }) => {
  const [name, setName] = useState(teamName || "");
  const [description, setDescription] = useState("");
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const errors = getErrors({
    name,
    description
  });
  const hasErrors = Object.keys(errors).length > 0;

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        if (isDisabled) {
          return;
        }
        setSubmitAttempted(true);
        if (hasErrors) {
          return;
        }

        const teamObject = new Team({
          name,
          description
        });
        onSubmit(teamObject);
      }}
    >
      <Label>Name</Label>
      <Input
        data-test-id="name-field"
        value={name}
        onChange={e => setName(e.target.value)}
      />
      {submitAttempted && errors.name && (
        <Error data-test-id="name-error">
          <P>{errors.name}</P>
        </Error>
      )}
      <Spacer bottom="md" as="div" />

      <Label>Description</Label>
      <Textarea
        data-test-id="description-field"
        palceholder="Optional"
        value={description}
        onChange={e => setDescription(e.target.value)}
      />
      <Spacer bottom="md" as="div" />

      <ButtonWrapper>
        <Button type="submit" isPrimary disabled={isDisabled}>
          Submit
        </Button>
      </ButtonWrapper>
    </form>
  );
};

NewTeamForm.propTypes = {
  /** A prefilled value for the team name */
  teamName: PropTypes.string,
  /** A function called when the form is submitted. Is provided with the Team to be saved */
  onSubmit: PropTypes.func.isRequired,
  /** Disables the form and prevens submission if true. */
  isDisabled: PropTypes.bool
};

export default NewTeamForm;
