import { fromJS } from "immutable";
import { handleActions } from "redux-actions";
import { CACHE_EXPIRATION } from "../../models/Competitor";
import {
  CREATE_COMPETITOR,
  GET_COMPETITORS,
  GET_COMPETITOR,
  GET_COMPETITIONS,
  GET_SCORES
} from "../actions";

export const defaultState = fromJS({
  competitors: {},
  findRequests: {}
});

function handleIncomingCompetitors(state, action, isDirectRequest) {
  const { payload } = action;

  // Do nothing if there are no competitors
  if (!payload.competitors) {
    return state;
  }

  // start building state
  let updated = state;

  // Store the competitors
  const competitorIds = []; // Build up an array of ids for later
  payload.competitors.forEach(competitor => {
    const id = competitor.get("id");
    updated = updated.setIn(["competitors", id], competitor);
    competitorIds.push(id);
  });

  // Prune old cached find requests
  const findRequests = updated.get("findRequests").toJS();
  const currentTime = new Date().getTime();
  Object.keys(findRequests).forEach(findRequestKey => {
    const findRequestData = findRequests[findRequestKey];
    if (currentTime - findRequestData.fetchTime > CACHE_EXPIRATION) {
      delete findRequests[findRequestKey];
    }
  });

  // Store the new find request
  findRequests[payload.findRequestKey] = {
    competitorIds,
    fetchTime: currentTime,
    totalCount: isDirectRequest ? payload.meta.totalCount : null
  };

  // Update state with find request changes
  updated = updated.set("findRequests", fromJS(findRequests));

  return updated;
}

/**
 * The reducer managing data about the Competitors.
 * Maintains an id-indexed map of competitiors as well as data about specific requests.
 */
export default handleActions(
  {
    [CREATE_COMPETITOR.RECEIVED]: (state, action) => {
      // Since we created a competitor, we need to clear out any list caches since
      // the new competitor may need to be included in them.
      const updated = state.set("findRequests", fromJS({}));
      return updated.setIn(
        ["competitors", action.payload.get("id")],
        action.payload
      );
    },
    [GET_COMPETITOR.RECEIVED]: (state, action) => {
      return state.setIn(
        ["competitors", action.payload.get("id")],
        action.payload
      );
    },
    [GET_COMPETITORS.RECEIVED]: (state, action) => {
      return handleIncomingCompetitors(state, action, true);
    },
    // Competitors can be side-loaded when getting competitions
    [GET_COMPETITIONS.RECEIVED]: (state, action) => {
      return handleIncomingCompetitors(state, action);
    },
    // Competitors can be side-loaded when getting scores
    [GET_SCORES.RECEIVED]: (state, action) => {
      return handleIncomingCompetitors(state, action);
    }
  },
  defaultState
);

/**
 * Gets competitors based on the provided find request.
 *
 * Accepts CompetitionFindReqeust in case competitions were sidelaoded as a part
 * of that request.
 *
 * @param  {Immutable.Map} state
 * @param  {CompetitionFindReqest|CompetitorFindRequest} findRequest
 * @return {Competitor[]|null}
 */
export const getCompetitors = (state, findRequest) => {
  const competitorsState = state.get("competitors");

  // When a find request is used to get competitors, we find the specific set
  // of competitors that are tied to that find request and return them.
  if (findRequest) {
    const findRequestKey = findRequest.get("key");

    // If the find request doesn't exist or is expired, return null so the
    // consumer knows to fetch the data.
    const findRequestData = competitorsState.getIn([
      "findRequests",
      findRequestKey
    ]);
    if (
      !findRequestData ||
      new Date().getTime() - findRequestData.get("fetchTime") > CACHE_EXPIRATION
    ) {
      return null;
    }

    // Get the relevant competitors
    return (
      findRequestData
        .get("competitorIds")
        // Get each competitor
        .map(competitorId =>
          competitorsState.getIn(["competitors", competitorId])
        )
        // Filter out those that may have been deleted or something
        .filter(competitor => !!competitor)
        .toJS()
    );
  }

  // If no find request is provided, just return what we have
  const competitorsMap = competitorsState.get("competitors");
  const competitorsArray = competitorsMap.valueSeq().toJS();
  return competitorsArray;
};

/**
 * Gets competitors based on the provided find request, indexed by Id.
 *
 * Accepts CompetitionFindReqeust in case competitions were sidelaoded as a part
 * of that request.
 *
 * @param  {Immutable.Map} state
 * @param  {CompetitionFindReqest} [findRequest]
 * @return {Object<Competitor>|null}
 */
export const getCompetitorsIndexedById = (state, findRequest) => {
  const competitors = getCompetitors(state, findRequest);
  if (!competitors) {
    return competitors;
  }
  return competitors.reduce((accumulated, competitor) => {
    accumulated[competitor.get("id")] = competitor;
    return accumulated;
  }, {});
};

/**
 * Returns the competitor based on competitor id.
 * @param  {Immutable.Map} state
 * @param  {String} competitorId
 * @return {Competitor|null}
 */
export const getCompetitor = (state, competitorId) => {
  const competitor = state.getIn(["competitors", "competitors", competitorId]);
  // If there is no competitor, or the competitor is stale, return null so the consumer
  // can fetch it.
  if (!competitor || competitor.get("isStale")) {
    return null;
  }
  return competitor;
};
