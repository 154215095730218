import showError from "../../utils/showError";
import { transformCompetitor } from "../../models/Competitor";

/**
 * Fetches a competitor by ID
 * @param  {String} competitorId The ID of the competitor to fetch
 * @return {Promise<Competitor>}
 */
export default async function getCompetitor(competitorId) {
  const response = await this.axiosInstance
    .get(`/competitors/${competitorId}`)
    .catch(e => {
      showError(e.response.data.error);
      return Promise.reject(e.response);
    });

  return transformCompetitor(response.data);
}
