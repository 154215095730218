import { fromJS } from "immutable";
import { handleActions } from "redux-actions";
import { CACHE_EXPIRATION } from "../../models/Event";
import { CREATE_EVENT, DELETE_EVENT, GET_EVENT, GET_EVENTS } from "../actions";

export const defaultState = fromJS({
  events: {},
  findRequests: {}
});

/**
 * The reducer managing data about Events
 * Maintains an id-indexed map of events as well as data about specific requests.
 */
export default handleActions(
  {
    [CREATE_EVENT.RECEIVED]: (state, action) => {
      // Since we created an event, we need to clear out any list caches since the
      // new event may need to be included in them.
      const updated = state.set("findRequests", fromJS({}));
      return updated.setIn(
        ["events", action.payload.get("id")],
        action.payload
      );
    },
    [DELETE_EVENT.RECEIVED]: (state, action) => {
      const id = action.payload;
      return state.deleteIn(["events", id]);
    },
    [GET_EVENT.RECEIVED]: (state, action) => {
      return state.setIn(["events", action.payload.get("id")], action.payload);
    },
    // When we retrieve events, overwrite the events and totalCount based on the response
    [GET_EVENTS.RECEIVED]: (state, action) => {
      const { payload } = action;

      // Start building state
      let updated = state;

      // Store the events
      const eventIds = []; // While we are at it, get a list of event ids to use later
      payload.events.forEach(event => {
        const id = event.get("id");
        updated = updated.setIn(["events", id], event);
        eventIds.push(id);
      });

      // Prune old cached find requests
      const findRequests = updated.get("findRequests").toJS();
      const currentTime = new Date().getTime();
      Object.keys(findRequests).forEach(findRequestKey => {
        const findRequestData = findRequests[findRequestKey];
        // 10 minute expiration
        if (currentTime - findRequestData.fetchTime > CACHE_EXPIRATION) {
          delete findRequests[findRequestKey];
        }
      });

      // Store the new find request
      findRequests[payload.findRequestKey] = {
        eventIds,
        fetchTime: currentTime,
        totalCount: payload.meta.totalCount
      };

      // Update state with find request changes
      updated = updated.set("findRequests", fromJS(findRequests));

      return updated;
    }
  },
  defaultState
);

/**
 * Returns the events in an order based on the provided find request.
 * @param  {Immutable.Map} state
 * @param  {EventFindRequest} [findRequest]
 * @return {Event[]|null}
 */
export const getEvents = (state, findRequest) => {
  const eventsState = state.get("events");

  // When a find request is used to get events, we find the specific set of
  // events that are tied to that find request and return them.
  if (findRequest) {
    const findRequestKey = findRequest.get("key");

    // If the find request doesn't exist or is expired, return null so the
    // consumer knows to fetch the data.
    const findRequestData = eventsState.getIn(["findRequests", findRequestKey]);
    if (
      !findRequestData ||
      new Date().getTime() - findRequestData.get("fetchTime") > CACHE_EXPIRATION
    ) {
      return null;
    }

    // Get the relevant events
    return (
      findRequestData
        .get("eventIds")
        // Get each event
        .map(eventId => eventsState.getIn(["events", eventId]))
        // Filter out those that may have been deleted or something
        .filter(event => !!event)
        .toJS()
    );
  }

  // If no find request is provided, just return what we have
  const eventsMap = eventsState.get("events");
  const eventsArray = eventsMap.valueSeq().toJS();
  return eventsArray;
};

/**
 * Returns the event based on event id.
 * @param  {Immutable.Map} state
 * @param  {String} eventId
 * @return {Event|null}
 */
export const getEvent = (state, eventId) => {
  const event = state.getIn(["events", "events", eventId]);
  // If there is no event, or the event is stale, return null so the consumer
  // can fetch it.
  if (!event || event.get("isStale")) {
    return null;
  }
  return event;
};
