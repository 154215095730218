import showError from "../../utils/showError";
import { transformCompetitor } from "../../models/Competitor";

/**
 * Fetches competitors based on the provided findrequest
 * @param {CompetitorFindRequest} findRequest
 * @return {Promise<Object>} Resolves to `{ competitors: Competitor[], meta: object }`
 */
export default async function getCompetitors(findRequest) {
  // Reduce the find request down to a simple payload
  const params = findRequest.get("payload");

  // request the competitors
  const response = await this.axiosInstance
    .get("/competitors", { params })
    .catch(e => {
      showError(e.response.data.error);
      return Promise.reject(e.response);
    });

  // Cast the competitors to a Competitor object
  const currentTime = new Date().getTime();
  const competitors = response.data.competitors.map(competitor => {
    return transformCompetitor(competitor, currentTime);
  });

  // Return the competitors and the meta data.
  return {
    competitors,
    meta: response.data.meta,
    findRequestKey: findRequest.get("key")
  };
}
