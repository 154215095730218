import { fromJS } from "immutable";
import { handleActions } from "redux-actions";
import { CACHE_EXPIRATION } from "../../models/Score";
import {
  CREATE_SCORE,
  DELETE_SCORE,
  GET_COMPETITIONS,
  GET_SCORES
} from "../actions";

export const defaultState = fromJS({
  scores: {},
  findRequests: {}
});

function handleIncomingScores(state, action) {
  const { payload } = action;

  // Do nothing if there are no scores
  if (!payload.scores) {
    return state;
  }

  // start building state
  let updated = state;

  // Store the scores
  const scoreIds = [];
  payload.scores.forEach(score => {
    const id = score.get("id");
    updated = updated.setIn(["scores", id], score);
    scoreIds.push(id);
  });

  // Prune old cached find requests
  const findRequests = updated.get("findRequests").toJS();
  const currentTime = new Date().getTime();
  Object.keys(findRequests).forEach(findRequestKey => {
    const findRequestData = findRequests[findRequestKey];
    if (currentTime - findRequestData.fetchTime > CACHE_EXPIRATION) {
      delete findRequests[findRequestKey];
    }
  });

  // Store the new find request
  findRequests[payload.findRequestKey] = {
    scoreIds,
    fetchTime: currentTime,
    totalCount: null
  };

  // Update state with find request changes
  updated = updated.set("findRequests", fromJS(findRequests));

  return updated;
}

/**
 * The reducer managing data about the Scores.
 * Maintains an id-indexed map of scores as well as data about specific requests.
 */
export default handleActions(
  {
    [CREATE_SCORE.RECEIVED]: (state, action) => {
      // Since we created a score, we need to clear out any list caches since
      // the new score may need to be included in them.
      const updated = state.set("findRequests", fromJS({}));
      return updated.setIn(
        ["scores", action.payload.get("id")],
        action.payload
      );
    },
    [DELETE_SCORE.RECEIVED]: (state, action) => {
      const id = action.payload;
      return state.deleteIn(["scores", id]);
    },
    // Scores can be side-loaded when getting competitions
    [GET_COMPETITIONS.RECEIVED]: (state, action) => {
      return handleIncomingScores(state, action);
    },
    [GET_SCORES.RECEIVED]: (state, action) => {
      return handleIncomingScores(state, action);
    }
  },
  defaultState
);

/**
 * Gets scores that have already been sideloaded by another request.
 * @param  {Immutable.Map} state
 * @param  {CompetitionFindReqest} findRequest
 * @return {Score[]|null}
 */
export const getScores = (state, findRequest) => {
  const scoresState = state.get("scores");

  // When a find request is used to get scores, we find the specific set
  // of scores that are tied to that find request and return them.
  if (findRequest) {
    const findRequestKey = findRequest.get("key");

    // If the find request doesn't exist or is expired, return null so the
    // consumer knows to fetch the data.
    const findRequestData = scoresState.getIn(["findRequests", findRequestKey]);
    if (
      !findRequestData ||
      new Date().getTime() - findRequestData.get("fetchTime") > CACHE_EXPIRATION
    ) {
      return null;
    }

    // Get the relevant scores
    return (
      findRequestData
        .get("scoreIds")
        // Get each score
        .map(scoreId => scoresState.getIn(["scores", scoreId]))
        // Filter out those that may have been deleted or something
        .filter(score => !!score)
        .toJS()
    );
  }

  // If no find request is provided, just return what we have
  const scoresMap = scoresState.get("scores");
  const scoresArray = scoresMap.valueSeq().toJS();
  return scoresArray;
};

export const getScoresGroupedBy = (state, findRequest, groupedBy) => {
  const scores = getScores(state, findRequest);
  if (!scores) {
    return scores;
  }
  return scores.reduce((accumulated, score) => {
    const groupValue = score.get(groupedBy);
    if (!accumulated[groupValue]) {
      accumulated[groupValue] = [];
    }
    accumulated[groupValue].push(score);
    return accumulated;
  }, {});
};

/**
 * Returns the score based on score id.
 * @param  {Immutable.Map} state
 * @param  {String} scoreId
 * @return {Score|null}
 */
export const getScore = (state, scoreId) => {
  const score = state.getIn(["scores", "scores", scoreId]);
  // If there is no score, or the score is stale, return null so the consumer
  // can fetch it.
  if (!score || score.get("isStale")) {
    return null;
  }
  return score;
};
