import { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CREATE_EVENT } from "../redux/actions";

/**
 * A container for creating new events.
 */
export const EventCreateContainer = ({ onSave, children }) => {
  const [savedEvent, setSavedEvent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function handleSave(event) {
    setIsLoading(true);
    const savedEvent = await onSave(event).catch(e => {
      setIsLoading(false);
      return Promise.reject(e);
    });
    setIsLoading(false);
    setSavedEvent(savedEvent);
  }

  return children({
    saveEvent: handleSave,
    isLoading,
    savedEvent
  });
};

EventCreateContainer.propTypes = {
  /** The method called when saving a new event. Provided by redux */
  onSave: PropTypes.func.isRequired,
  /** A function provided with data for rendering child components */
  children: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  onSave: CREATE_EVENT
};

export default connect(
  () => ({}),
  mapDispatchToProps
)(EventCreateContainer);
