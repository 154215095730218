import React from "react";
import PropTypes from "prop-types";
import { Button, ModalConfirm } from "react-watty-ui";
import { Redirect } from "react-router-dom";
import Competition from "../../models/Competition";
import Event from "../../models/Event";
import UserContainer from "../../containers/UserContainer";
import CompetitionContainer from "../../containers/CompetitionContainer";

/**
 * A button to delete a competition.
 */
const DeleteCompetitionButton = ({ competition, event, ...props }) => (
  <UserContainer>
    {userData => (
      <CompetitionContainer competitionId={competition.get("id")}>
        {competitionData => (
          <ModalConfirm
            onConfirm={competitionData.delete}
            message="Are you sure you want to delete this competition?"
          >
            {modal => {
              if (competitionData.isDeleted) {
                return <Redirect to={`/events/${event.get("id")}`} />;
              }
              if (!userData.isAdmin) {
                return null;
              }

              const canDelete = competition.get("scoreIds").length === 0;
              return (
                <Button
                  disabled={competitionData.isDeleting || !canDelete}
                  onClick={canDelete ? modal.actions.open : () => {}}
                  title={
                    canDelete
                      ? `Delete ${competition.get("title")}`
                      : `Cannot delete the competition because it contains one or more scores.`
                  }
                  {...props}
                >
                  Delete
                </Button>
              );
            }}
          </ModalConfirm>
        )}
      </CompetitionContainer>
    )}
  </UserContainer>
);

DeleteCompetitionButton.propTypes = {
  /** The competition to be deleted */
  competition: PropTypes.instanceOf(Competition),
  /** The event that the competition belongs to */
  event: PropTypes.instanceOf(Event)
};

export default DeleteCompetitionButton;
