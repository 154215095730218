import showError from "../../utils/showError";
import showSuccess from "../../utils/showSuccess";
import { transformCompetitor } from "../../models/Competitor";

/**
 * Saves a Competitor on the server.
 * @param {Competitor} competitor A competitor to save
 * @return {Promise<Competitor>} The competitor returned from the server after saving
 */
export default async function createCompetitor(competitor) {
  const response = await this.axiosInstance
    .post("/competitors", competitor.getProperties("name", "akaMemberId"))
    .catch(e => {
      showError(e.response.data.error);
      return Promise.reject(e);
    });

  showSuccess("A Competitor has been created.");

  // Build and return the competitor
  return transformCompetitor(response.data);
}
