import showError from "../../utils/showError";
import { transformTeam } from "../../models/Team";
import { transformScore } from "../../models/Score";
import { transformCompetitor } from "../../models/Competitor";
import { transformCompetition } from "../../models/Competition";

/**
 * Fetches competitions based on the provided findrequest
 * @param {CompetitionFindRequest} findRequest
 * @return {Promise<Object>} Resolves to `{ competitions: Competition[], meta: object }`
 */
export default async function getCompetitions(findRequest) {
  // Reduce the find request down to a simple payload
  const params = findRequest.get("payload");

  // request the competitions
  const response = await this.axiosInstance
    .get("/competitions", { params })
    .catch(e => {
      showError(e.response.data.error);
      return Promise.reject(e.response);
    });

  // Cast the competitions to a Competition object
  const currentTime = new Date().getTime();
  const competitions = response.data.competitions.map(competition => {
    return transformCompetition(competition, currentTime);
  });

  const competitors = response.data.competitors
    ? response.data.competitors.map(competitor => {
        return transformCompetitor(competitor, currentTime);
      })
    : null;

  const scores = response.data.scores
    ? response.data.scores.map(score => {
        return transformScore(score, currentTime);
      })
    : null;

  const teams = response.data.teams
    ? response.data.teams.map(team => {
        return transformTeam(team, currentTime);
      })
    : null;

  // Return the competitions and the meta data.
  return {
    competitions,
    competitors,
    scores,
    teams,
    meta: response.data.meta,
    findRequestKey: findRequest.get("key")
  };
}
