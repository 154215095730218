import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Input,
  Label,
  Select,
  Textarea,
  Spacer,
  Button,
  Error,
  P
} from "react-watty-ui";
import Score, { INVALID_TYPES, STATUSES } from "../../models/Score";
import titleCase from "../../utils/titleCase";
import CompetitorsField from "./CompetitorsField";
import TeamField from "./TeamField";

const ButtonWrapper = styled.div`
  text-align: right;
`;

function getErrors(data) {
  const errors = {};
  if (!data.score && data.score !== 0) {
    errors.score = "A score must be provided.";
  } else if (data.score < 0 || data.score > 100) {
    errors.score = "The score must be between 0 and 100.";
  }

  if (data.status === "invalid" && !data.invalidType) {
    errors.invalidType =
      "An invalid type must be selected if the score is invalid.";
  }

  if (!data.competitorIds.length) {
    errors.competitorIds = "At least one competitor must be selected.";
  } else if (data.competitorIds.length > 1 && !data.teamId) {
    errors.teamId =
      "A team must be selected if there are more than one competitors.";
  }

  return errors;
}

const NewCompetitionForm = ({ onSubmit, isDisabled, competitionId }) => {
  const [score, setScore] = useState(null);
  const [invalidType, setInvalidType] = useState("");
  const [notes, setNotes] = useState("");
  const [status, setStatus] = useState("active");
  const [teamId, setTeamId] = useState(null);
  const [competitorIds, setCompetitorIds] = useState([]);
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const errors = getErrors({
    score,
    invalidType,
    notes,
    status,
    teamId,
    competitorIds
  });
  const hasErrors = Object.keys(errors).length > 0;

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        if (isDisabled) {
          return;
        }
        setSubmitAttempted(true);
        if (hasErrors) {
          return;
        }

        const scoreObject = new Score({
          competitionId,
          score,
          invalidType: status === "invalid" ? invalidType : null,
          notes,
          status,
          teamId: teamId || null,
          competitorIds
        });
        onSubmit(scoreObject);
      }}
    >
      <Label>Score</Label>
      <Input
        data-test-id="score-field"
        value={!score && score !== 0 ? "" : score.toString()}
        type="number"
        min="0"
        max="100"
        onChange={e => setScore(parseInt(e.target.value))}
      />
      {submitAttempted && errors.score && (
        <Error data-test-id="score-error">
          <P>{errors.score}</P>
        </Error>
      )}
      <Spacer bottom="md" as="div" />

      <Label>Status</Label>
      <Select
        value={status}
        onChange={e => setStatus(e.target.value)}
        data-test-id="status-field"
      >
        {STATUSES.map(statusOption => (
          <option key={statusOption} value={statusOption}>
            {titleCase(statusOption)}
          </option>
        ))}
      </Select>
      <Spacer bottom="md" as="div" />

      {status === "invalid" && (
        <React.Fragment>
          <Label>Invalid Reason</Label>
          <Select
            value={invalidType}
            onChange={e => setInvalidType(e.target.value)}
            data-test-id="invalidType-field"
          >
            <option>Select One</option>
            {INVALID_TYPES.map(invalidTypeOption => (
              <option key={invalidTypeOption} value={invalidTypeOption}>
                {titleCase(invalidTypeOption)}
              </option>
            ))}
          </Select>
          {submitAttempted && errors.invalidType && (
            <Error data-test-id="invalidType-error">
              <P>{errors.invalidType}</P>
            </Error>
          )}
          <Spacer bottom="md" as="div" />
        </React.Fragment>
      )}

      <Label>Competitor(s)</Label>
      <CompetitorsField
        data-test-id="competitorIds-field"
        competitorIds={competitorIds}
        onChange={updatedIds => setCompetitorIds(updatedIds)}
      />
      {submitAttempted && errors.competitorIds && (
        <Error data-test-id="competitorIds-error">
          <P>{errors.competitorIds}</P>
        </Error>
      )}
      <Spacer bottom="md" as="div" />

      <Label>Team</Label>
      <TeamField
        data-test-id="teamId-field"
        teamId={teamId}
        onChange={id => setTeamId(id)}
      />
      {submitAttempted && errors.teamId && (
        <Error data-test-id="teamId-error">
          <P>{errors.teamId}</P>
        </Error>
      )}
      <Spacer bottom="md" as="div" />

      <Label>Notes</Label>
      <Textarea
        data-test-id="notes-field"
        palceholder="Optional"
        value={notes}
        onChange={e => setNotes(e.target.value)}
      />
      <Spacer bottom="md" as="div" />

      <ButtonWrapper>
        <Button type="submit" isPrimary disabled={isDisabled}>
          Submit
        </Button>
      </ButtonWrapper>
    </form>
  );
};

NewCompetitionForm.defaultProps = {
  isDisabled: false
};

NewCompetitionForm.propTypes = {
  /**
   * Called when the form is submitted. Provided with a new Score as the first
   * parameter.
   */
  onSubmit: PropTypes.func.isRequired,
  /** Should the form be disabled? */
  isDisabled: PropTypes.bool,
  /** The ID of the competition that we are creating a score for */
  competitionId: PropTypes.string.isRequired
};

export default NewCompetitionForm;
