import React from "react";
import PropTypes from "prop-types";
import { Loading, Spacer, P } from "react-watty-ui";
import CompetitionPageContainer from "../../containers/pages/CompetitionPageContainer";
import ErrorPage from "../ErrorPage";
import PageWrapper from "../PageWrapper";
import CompetitionHeading from "./CompetitionHeading";
import ScoresDisplay from "./ScoresDisplay";
import CreateScoreLink from "./CreateScoreLink";

/**
 * Top level layout component for the Competition page.
 */
const Competition = ({ match }) => {
  const eventId = match.params.eventId;
  const competitionId = match.params.competitionId;
  return (
    <CompetitionPageContainer eventId={eventId} competitionId={competitionId}>
      {({ eventData, competitionData, scoresData }) => {
        const isLoading =
          eventData.isLoading ||
          competitionData.isLoading ||
          scoresData.isLoading;
        if (isLoading) {
          return <Loading />;
        }

        if (!eventData.event || !competitionData.competition) {
          return (
            <ErrorPage
              errorCode={
                eventData.errorCode || competitionData.errorCode || 404
              }
              errorMessage={
                eventData.errorMessage ||
                competitionData.errorMessage ||
                "Competition not found"
              }
            />
          );
        }
        return (
          <PageWrapper data-test-id="content">
            <CompetitionHeading
              event={eventData.event}
              competition={competitionData.competition}
              isLoading={eventData.isLoading || competitionData.isLoading}
            />
            <Spacer top="lg" bottom="md" as="div">
              <CreateScoreLink
                eventId={eventId}
                competitionId={competitionId}
              />
            </Spacer>
            {scoresData.scores && scoresData.scores.length ? (
              <ScoresDisplay
                scores={scoresData.scores}
                competitors={scoresData.competitors}
                teams={scoresData.teams}
              />
            ) : (
              <P data-test-id="empty-state">
                No scores have been recorded for this competition.
              </P>
            )}
          </PageWrapper>
        );
      }}
    </CompetitionPageContainer>
  );
};

Competition.propTypes = {
  /** The route match data provided by Route */
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventId: PropTypes.string.isRequired,
      competitionId: PropTypes.string.isRequired
    })
  }).isRequired
};

export default Competition;
