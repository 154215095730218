import { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CREATE_COMPETITION } from "../redux/actions";

/**
 * A container for creating new competitions.
 */
export const CompetitionCreateContainer = ({ onSave, children }) => {
  const [savedCompetition, setSavedCompetition] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function handleSave(competition) {
    setIsLoading(true);
    const savedCompetition = await onSave(competition).catch(e => {
      setIsLoading(false);
      return Promise.reject(e);
    });
    setIsLoading(false);
    setSavedCompetition(savedCompetition);
  }

  return children({
    saveCompetition: handleSave,
    isLoading,
    savedCompetition
  });
};

CompetitionCreateContainer.propTypes = {
  /** The method called when saving a new competition. Provided by redux */
  onSave: PropTypes.func.isRequired,
  /** A function provided with data for rendering child components */
  children: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  onSave: CREATE_COMPETITION
};

export default connect(
  () => ({}),
  mapDispatchToProps
)(CompetitionCreateContainer);
