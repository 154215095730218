import React from "react";
import PropTypes from "prop-types";
import CompetitionFindRequest from "../../models/findRequests/CompetitionFindRequest";
import EventContainer from "../EventContainer";
import CompetitionsContainer from "../CompetitionsContainer";
import UserContainer from "../UserContainer";

/**
 * Container that coordinates data for the event page.
 */
const EventPageContainer = ({ eventId, children }) => {
  const competitionsFindRequest = new CompetitionFindRequest({
    eventIdList: [eventId],
    include: ["scores", "scores_competitors", "scores_team"]
  });
  return (
    <EventContainer eventId={eventId}>
      {eventData => (
        <CompetitionsContainer findRequest={competitionsFindRequest}>
          {competitionsData => (
            <UserContainer>
              {userData =>
                children({
                  eventData,
                  competitionsData,
                  userData
                })
              }
            </UserContainer>
          )}
        </CompetitionsContainer>
      )}
    </EventContainer>
  );
};

EventPageContainer.propTypes = {
  /** The ID of the event to get data for */
  eventId: PropTypes.string.isRequired,
  /** A function to be provided with `eventData` and `competitionsData`. */
  children: PropTypes.func.isRequired
};

export default EventPageContainer;
