import React from "react";
import { Route, Switch } from "react-router-dom";
import Competition from "./competition/Competition";
import ErrorPage from "./ErrorPage";
import Event from "./event/Event";
import Events from "./events/Events";
import Home from "./home/Home";
import Login from "./login/Login";
import NewCompetition from "./newCompetition/NewCompetition";
import NewEvent from "./newEvent/NewEvent";
import NewScore from "./newScore/NewScore";

const Router = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/events" component={Events} />
    <Route exact path="/events/new" component={NewEvent} />
    <Route exact path="/events/:eventId" component={Event} />
    <Route
      exact
      path="/events/:eventId/competitions/new"
      component={NewCompetition}
    />
    <Route
      exact
      path="/events/:eventId/competitions/:competitionId"
      component={Competition}
    />
    <Route
      exact
      path="/events/:eventId/competitions/:competitionId/scores/new"
      component={NewScore}
    />
    <Route exact path="/login" component={Login} />
    <Route component={ErrorPage} />
  </Switch>
);

export default Router;
