import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TeamFindRequest from "../models/findRequests/TeamFindRequest";
import { getTeams } from "../redux/modules/teams";
import Team from "../models/Team";
import { GET_TEAMS } from "../redux/actions";

/**
 * A container that deals with requesting teams based on the provided findRequest.
 */
export class TeamsContainer extends React.Component {
  static propTypes = {
    /** A function which is provided with `refresh()`, `teams`, and `isLoading` */
    children: PropTypes.func.isRequired,
    /** A function to call when the teams need to be fetched. Provided by redux. */
    onFetch: PropTypes.func.isRequired,
    /** The fetched array of Teams. Provided by redux. */
    teams: PropTypes.arrayOf(PropTypes.instanceOf(Team)),
    /** The find request used to control the requests being made */
    findRequest: PropTypes.instanceOf(TeamFindRequest)
  };

  static defaultProps = {
    findRequest: new TeamFindRequest()
  };

  state = {
    isLoading: true
  };

  // Fetch teams when the component is mounted if we don't already have a
  // cached request
  componentDidMount() {
    if (this.props.teams === null) {
      this.getTeams();
    } else {
      this.setState({ isLoading: false });
    }
  }

  // Fetch teams when the findRequest changs if we don't already have a
  // cached request
  componentDidUpdate(prevProps) {
    if (
      prevProps.findRequest !== this.props.findRequest &&
      this.props.teams === null
    ) {
      this.getTeams();
    }
  }

  getTeams = async () => {
    this.setState({ isLoading: true });
    await this.props
      .onFetch(this.props.findRequest)
      // If the request fails, we throw a toast from the API layer. No sepcial
      // handling here.
      .catch(() => {});
    this.setState({ isLoading: false });
  };

  render() {
    return this.props.children({
      refresh: this.getTeams,
      teams: this.props.teams || [],
      isLoading: this.state.isLoading
    });
  }
}

const mapStateToProps = (state, props) => ({
  teams: getTeams(state, props.findRequest)
});

const mapDispatchToProps = {
  onFetch: GET_TEAMS
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamsContainer);
