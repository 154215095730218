import PropTypes from "prop-types";
import { computed, createModel } from "manikin-model";

export const INVALID_TYPES = ["disqualified", "non-member"];

export const STATUSES = ["active", "invalid"];

/**
 * A cache expiration for Scores. Any score or list of competitions that has
 * been cached for more than this duration will be fetched the next time it is
 * requested.
 */
export const CACHE_EXPIRATION = 30 * 60 * 1000; // 30 minutes

/**
 * An individual score that was achieved during a Competition
 */
const Score = createModel("Score", {
  id: null,
  competitionId: null,
  score: null,
  invalidType: null,
  notes: "",
  status: "active",
  isInvalid: computed(function() {
    return this.get("status") === "invalid";
  }),
  teamId: null,
  competitorIds: [],
  fetchTime: null,
  isStale: computed(function() {
    return new Date().getTime() - this.get("fetchTime") > CACHE_EXPIRATION;
  })
});

Score.prototype.propTypes = {
  /** The id of the score */
  id: PropTypes.string,
  /** The ID of the competition that this score is realted to */
  competitionId: PropTypes.string,
  /** The actual score, a value from 0 - 100 */
  score: PropTypes.number,
  /** A reason for why the status is invalid, if the status is invalid */
  invalidType: PropTypes.string,
  /** Some open notes about the score */
  notes: PropTypes.string,
  /** The status of the score. Either "active" or "invalid" */
  status: PropTypes.string,
  /** Indicates if the score is invalid due to disqualification or something */
  isInvalid: PropTypes.bool,
  /** Ids of the competitors involved in this score */
  competitorIds: PropTypes.arrayOf(PropTypes.string),
  /** The ID of the Team involved in this score */
  teamId: PropTypes.string,
  /** The new Date().getTime() from when the record was fetched */
  fetchTime: PropTypes.number,
  /** Indicates if the record is stale and needs to be refetched */
  isStale: PropTypes.bool
};

export default Score;

/**
 * Transforms raw score data into a Score.
 * @param  {Object} rawScore
 * @param  {Number} [fetchTime=currentTime] when the record was retrieved (ms)
 * @return {Score}
 */
export function transformScore(rawScore, fetchTime = new Date().getTime()) {
  const teamId = rawScore.teamId;
  const hasTeamId = teamId !== null && teamId !== undefined;
  return new Score({
    id: rawScore.id.toString(),
    competitionId: rawScore.competitionId.toString(),
    score: rawScore.score,
    invalidType: rawScore.invalidType,
    notes: rawScore.notes,
    status: rawScore.status,
    competitorIds: rawScore.competitorIds.map(id => id.toString()),
    teamId: hasTeamId ? teamId.toString() : null,
    fetchTime
  });
}

export function getMockScore(overrides = {}) {
  return new Score({
    id: "123",
    competitionId: "1",
    score: 98.123,
    competitorIds: ["2"],
    fetchTime: new Date().getTime(),
    ...overrides
  });
}
