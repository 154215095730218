import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import EventFindRequest from "../models/findRequests/EventFindRequest";
import { getEvents } from "../redux/modules/events";
import Event from "../models/Event";
import { GET_EVENTS } from "../redux/actions";

/**
 * A container that deals with requesting events based on the provided findRequest.
 */
export class EventsContainer extends React.Component {
  static propTypes = {
    /** A function which is provided with `refresh()`, `events`, and `isLoading` values */
    children: PropTypes.func.isRequired,
    /** A function to call when events need to be fetched. Provided by redux. */
    getEvents: PropTypes.func.isRequired,
    /** The fetched array of Events. Provided by redux. */
    events: PropTypes.arrayOf(PropTypes.instanceOf(Event)),
    /** The find request used to control the requests being made. */
    findRequest: PropTypes.instanceOf(EventFindRequest)
  };

  static defaultProps = {
    findRequest: new EventFindRequest()
  };

  state = {
    isLoading: true
  };

  // Fetch events when the component is mounted if we don't already have a cached request
  componentDidMount() {
    if (this.props.events === null) {
      this.getEvents();
    } else {
      this.setState({ isLoading: false });
    }
  }

  // Fetch events when the findRequest changes if we don't already have a cached request
  componentDidUpdate(prevProps) {
    if (
      prevProps.findRequest !== this.props.findRequest &&
      this.props.events === null
    ) {
      this.getEvents();
    }
  }

  // Fetch events based on the findRequest
  getEvents = async () => {
    this.setState({ isLoading: true });
    await this.props
      .getEvents(this.props.findRequest)
      // If the request fails, we throw a toast from the API layer. No special
      // handling here.
      .catch(() => {});
    this.setState({ isLoading: false });
  };

  render() {
    return this.props.children({
      refresh: this.getEvents,
      events: this.props.events || [],
      isLoading: this.state.isLoading
    });
  }
}

const mapStateToProps = (state, props) => ({
  events: getEvents(state, props.findRequest)
});

const mapDispatchToProps = {
  getEvents: GET_EVENTS
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsContainer);
