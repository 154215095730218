import showError from "../../utils/showError";
import { transformTeam } from "../../models/Team";

/**
 * Fetches teams based on the provided findrequest
 * @param {TeamFindRequest} findRequest
 * @return {Promise<Object>} Resolves to `{ teams: Team[], meta: object }`
 */
export default async function getTeams(findRequest) {
  // Reduce the find request down to a simple payload
  const params = findRequest.get("payload");

  // request the teams
  const response = await this.axiosInstance
    .get("/teams", { params })
    .catch(e => {
      showError(e.response.data.error);
      return Promise.reject(e.response);
    });

  // Cast the teams to a Team object
  const currentTime = new Date().getTime();
  const teams = response.data.teams.map(team => {
    return transformTeam(team, currentTime);
  });

  // Return the teams and the meta data.
  return {
    teams,
    meta: response.data.meta,
    findRequestKey: findRequest.get("key")
  };
}
