import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";
import {
  Label,
  Select,
  DatePicker,
  Spacer,
  Button,
  Error,
  P
} from "react-watty-ui";
import Competition, {
  DISCIPLINES,
  COMPETITOR_TYPES,
  LEVELS,
  CATEGORIES
} from "../../models/Competition";
import titleCase from "../../utils/titleCase";

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    flex-grow: 1;
  }
`;

const ButtonWrapper = styled.div`
  text-align: right;
`;

function getErrors(data) {
  const errors = {};
  if (!data.discipline) {
    errors.discipline = "A discipline must be selected.";
  }

  if (!data.competitorType) {
    errors.competitorType = "A competitor type must be selected.";
  }

  if (!data.level) {
    errors.level = "A class must be selected.";
  }

  if (!data.date) {
    errors.date = "A date must be provided.";
  }

  if (!data.category) {
    errors.category = "A category must be selected.";
  }

  return errors;
}

const NewCompetitionForm = ({ onSubmit, isDisabled, eventId }) => {
  const [discipline, setDiscipline] = useState("");
  const [competitorType, setCompetitorType] = useState("");
  const [level, setLevel] = useState("");
  const [date, setDate] = useState(null);
  const [category, setCategory] = useState("");
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const errors = getErrors({
    discipline,
    competitorType,
    level,
    date,
    category
  });
  const hasErrors = Object.keys(errors).length > 0;

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        if (isDisabled) {
          return;
        }
        setSubmitAttempted(true);
        if (hasErrors) {
          return;
        }

        const competition = new Competition({
          eventId,
          discipline,
          level,
          competitorType,
          date: moment(date).format("YYYY-MM-DD"),
          category,
          scoreIds: []
        });
        onSubmit(competition);
      }}
    >
      <Label>Competition Date</Label>
      <P>
        <small>When did/will this competition take place?</small>
      </P>
      <DatePicker
        data-test-id="date-field"
        selected={date}
        onChange={selectedDate => setDate(selectedDate)}
      />
      {submitAttempted && errors.date && (
        <Error data-test-id="date-error">
          <P>{errors.date}</P>
        </Error>
      )}

      <FlexWrapper>
        <Spacer right="md" as="div">
          <Label>Discipline</Label>
          <Select
            value={discipline}
            onChange={e => setDiscipline(e.target.value)}
            data-test-id="discipline-field"
          >
            <option>Select One</option>
            {DISCIPLINES.map(disciplineOption => (
              <option key={disciplineOption} value={disciplineOption}>
                {titleCase(disciplineOption)}
              </option>
            ))}
          </Select>
          {submitAttempted && errors.discipline && (
            <Error data-test-id="discipline-error">
              <P>{errors.discipline}</P>
            </Error>
          )}
          <Spacer bottom="md" as="div" />
        </Spacer>

        <Spacer right="md" as="div">
          <Label>Competitor Type</Label>
          <Select
            value={competitorType}
            onChange={e => setCompetitorType(e.target.value)}
            data-test-id="competitorType-field"
          >
            <option>Select One</option>
            {COMPETITOR_TYPES.map(competitorTypeOption => (
              <option key={competitorTypeOption} value={competitorTypeOption}>
                {titleCase(competitorTypeOption)}
              </option>
            ))}
          </Select>
          {submitAttempted && errors.competitorType && (
            <Error data-test-id="competitorType-error">
              <P>{errors.competitorType}</P>
            </Error>
          )}
          <Spacer bottom="md" as="div" />
        </Spacer>

        <Spacer right="md" as="div">
          <Label>Class</Label>
          <Select
            value={level}
            onChange={e => setLevel(e.target.value)}
            data-test-id="level-field"
          >
            <option>Select One</option>
            {LEVELS.map(levelOption => (
              <option key={levelOption} value={levelOption}>
                {titleCase(levelOption)}
              </option>
            ))}
          </Select>
          {submitAttempted && errors.level && (
            <Error data-test-id="level-error">
              <P>{errors.level}</P>
            </Error>
          )}
          <Spacer bottom="md" as="div" />
        </Spacer>

        <Spacer right="md" as="div">
          <Label>Category</Label>
          <Select
            value={category}
            onChange={e => setCategory(e.target.value)}
            data-test-id="category-field"
          >
            <option>Select One</option>
            {CATEGORIES.map(categoryOption => (
              <option key={categoryOption} value={categoryOption}>
                {titleCase(categoryOption)}
              </option>
            ))}
          </Select>
          {submitAttempted && errors.category && (
            <Error data-test-id="category-error">
              <P>{errors.category}</P>
            </Error>
          )}
          <Spacer bottom="md" as="div" />
        </Spacer>
      </FlexWrapper>

      <ButtonWrapper>
        <Button type="submit" isPrimary disabled={isDisabled}>
          Submit
        </Button>
      </ButtonWrapper>
    </form>
  );
};

NewCompetitionForm.defaultProps = {
  isDisabled: false
};

NewCompetitionForm.propTypes = {
  /**
   * Called when the form is submitted. Provided with a new Competition as the
   * first parameter.
   */
  onSubmit: PropTypes.func.isRequired,
  /** Should the form be disabled? */
  isDisabled: PropTypes.bool,
  /** The ID of the event that we are creating a competition for */
  eventId: PropTypes.string.isRequired
};

export default NewCompetitionForm;
