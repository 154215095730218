import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Loading, H2, Spacer, P, Button } from "react-watty-ui";
import styled from "styled-components";
import Event from "../../models/Event";
import Competition from "../../models/Competition";
import Score from "../../models/Score";
import Competitor from "../../models/Competitor";
import Team from "../../models/Team";
import CompetitionTile from "./CompetitionTile";

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: center;
`;

const FlexHeading = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * Displays information about the competitions for a specific event
 */
const EventCompetitions = ({
  event,
  competitions,
  scores,
  competitors,
  teams,
  isLoading,
  isAdmin
}) => {
  if (isLoading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <FlexHeading data-test-id="content">
        <Spacer as={H2} right="md">
          Competitions
        </Spacer>
        {isAdmin && (
          <Button
            data-test-id="new-event"
            as={Link}
            to={`/events/${event.get("id")}/competitions/new`}
          >
            Add Competition
          </Button>
        )}
      </FlexHeading>
      <Spacer as="div" bottom="md" />
      <Flex>
        {!competitions.length && (
          <P data-test-id="empty">
            No competitions have been recorded at this event.
          </P>
        )}
        {competitions.map(competition => {
          const relevantScores = scores[competition.get("id")] || [];
          let relevantCompetitorIds = [];
          const relevantTeamIds = [];
          relevantScores.forEach(score => {
            relevantTeamIds.push(score.get("teamId"));
            relevantCompetitorIds = relevantCompetitorIds.concat(
              score.get("competitorIds")
            );
          });
          const relevantCompetitors = relevantCompetitorIds
            // Remove duplicates
            .reduce((accumulated, id) => {
              if (!accumulated.includes(id)) {
                accumulated.push(id);
              }
              return accumulated;
            }, [])
            // Convert to competitors
            .reduce((accumulated, id) => {
              accumulated[id] = competitors[id];
              return accumulated;
            }, {});

          const relevantTeams = relevantTeamIds
            // Remove duplicates
            .reduce((accumulated, id) => {
              if (!accumulated.includes(id)) {
                accumulated.push(id);
              }
              return accumulated;
            }, [])
            // Convert to teams
            .reduce((accumulated, id) => {
              accumulated[id] = teams[id];
              return accumulated;
            }, {});

          return (
            <CompetitionTile
              key={competition.get("id")}
              event={event}
              competition={competition}
              scores={relevantScores}
              competitors={relevantCompetitors}
              teams={relevantTeams}
            />
          );
        })}
      </Flex>
    </React.Fragment>
  );
};

EventCompetitions.propTypes = {
  /** The event we are showing competitions for */
  event: PropTypes.instanceOf(Event).isRequired,
  /** The competitions to display */
  competitions: PropTypes.arrayOf(PropTypes.instanceOf(Competition)),
  /** Scores related to the competitions */
  scores: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.instanceOf(Score)))
    .isRequired,
  /** Competitors related to the competitions */
  competitors: PropTypes.objectOf(PropTypes.instanceOf(Competitor)),
  /** Teams related to the competitions */
  teams: PropTypes.objectOf(PropTypes.instanceOf(Team)),
  /** Indiciation if the competitions are still loading */
  isLoading: PropTypes.bool,
  /** Indicates if the user is an admin and can add competitions */
  isAdmin: PropTypes.bool
};

export default EventCompetitions;
