import showError from "../../utils/showError";
import { transformTeam } from "../../models/Team";
import { transformScore } from "../../models/Score";
import { transformCompetitor } from "../../models/Competitor";

/**
 * Fetches scores based on the provided findrequest
 * @param {ScoreFindRequest} findRequest
 * @return {Promise<Object>} Resolves to `{ scores: Score[], meta: object }`
 */
export default async function getScores(findRequest) {
  // Reduce the find request down to a simple payload
  const params = findRequest.get("payload");

  // request the competitions
  const response = await this.axiosInstance
    .get("/scores", { params })
    .catch(e => {
      showError(e.response.data.error);
      return Promise.reject(e.response);
    });

  // Cast the competitions to a Competition object
  const currentTime = new Date().getTime();
  const scores = response.data.scores.map(score => {
    return transformScore(score, currentTime);
  });

  const competitors = response.data.competitors
    ? response.data.competitors.map(competitor => {
        return transformCompetitor(competitor, currentTime);
      })
    : null;

  const teams = response.data.teams
    ? response.data.teams.map(team => {
        return transformTeam(team, currentTime);
      })
    : null;

  // Return the scores and the meta data.
  return {
    competitors,
    scores,
    teams,
    meta: response.data.meta,
    findRequestKey: findRequest.get("key")
  };
}
