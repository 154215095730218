import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { A, H1, P, Loading } from "react-watty-ui";
import EventContainer from "../../containers/EventContainer";
import CompetitionContainer from "../../containers/CompetitionContainer";

/**
 * A header for the New Competition page that includes event information.
 */
const NewScoreHeading = ({ eventId, competitionId }) => (
  <EventContainer eventId={eventId}>
    {eventData => (
      <CompetitionContainer competitionId={competitionId}>
        {competitionData => (
          <React.Fragment>
            <H1>New Score</H1>
            {competitionData.isLoading || eventData.isLoading ? (
              <Loading />
            ) : (
              <P data-test-id="for-competition">
                Creating a score for{" "}
                <A
                  as={Link}
                  to={`/events/${eventId}/competitions/${competitionId}`}
                >
                  {competitionData.competition.get("title")}
                </A>{" "}
                at{" "}
                <A as={Link} to={`/events/${eventId}`}>
                  {eventData.event.get("name")}
                </A>
                .
              </P>
            )}
          </React.Fragment>
        )}
      </CompetitionContainer>
    )}
  </EventContainer>
);

NewScoreHeading.propTypes = {
  /** The ID of the event that the competiiton is being created for */
  eventId: PropTypes.string.isRequired,
  /** The ID of the competition that the score is being created for */
  competitionId: PropTypes.string.isRequired
};

export default NewScoreHeading;
