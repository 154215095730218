import showError from "../../utils/showError";
import showSuccess from "../../utils/showSuccess";
import { transformEvent } from "../../models/Event";

/**
 * Saves an Event on the server.
 * @param {Event} event An event to save
 * @return {Promise<Event>} The event returned from the server after saving
 */
export default async function createEvent(event) {
  const response = await this.axiosInstance
    .post(
      "/events",
      event.getProperties(
        "name",
        "description",
        "startDate",
        "endDate",
        "city",
        "state",
        "region"
      )
    )
    .catch(e => {
      showError(e.response.data.error);
      return Promise.reject(e);
    });

  showSuccess("An Event has been created.");

  // Build and return the event
  return transformEvent(response.data);
}
