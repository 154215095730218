import showError from "../../utils/showError";
import { transformScore } from "../../models/Score";

/**
 * Fetches a score by ID
 * @param  {String} scoreId The ID of the score to fetch
 * @return {Promise<Score>}
 */
export default async function getScore(scoreId) {
  const response = await this.axiosInstance
    .get(`/scores/${scoreId}`)
    .catch(e => {
      showError(e.response.data.error);
      return Promise.reject(e.response);
    });

  return transformScore(response.data);
}
