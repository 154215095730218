import { fromJS } from "immutable";
import { handleActions } from "redux-actions";
import { CACHE_EXPIRATION } from "../../models/Competition";
import {
  CREATE_COMPETITION,
  DELETE_COMPETITION,
  GET_COMPETITION,
  GET_COMPETITIONS
} from "../actions";

export const defaultState = fromJS({
  competitions: {},
  findRequests: {}
});

/**
 * The reducer managing data about Competitions
 * Maintains an id-indexed map of competitions as well as data about specific requests.
 */
export default handleActions(
  {
    [CREATE_COMPETITION.RECEIVED]: (state, action) => {
      // Since we created a competition, we need to clear out any list caches
      // since the new competition may need to be included in them.
      const updated = state.set("findRequests", fromJS({}));
      return updated.setIn(
        ["competitions", action.payload.get("id")],
        action.payload
      );
    },
    [DELETE_COMPETITION.RECEIVED]: (state, action) => {
      const id = action.payload;
      return state.deleteIn(["competitions", id]);
    },
    [GET_COMPETITION.RECEIVED]: (state, action) => {
      return state.setIn(
        ["competitions", action.payload.get("id")],
        action.payload
      );
    },
    [GET_COMPETITIONS.RECEIVED]: (state, action) => {
      const { payload } = action;

      // start building state
      let updated = state;

      // Store the competitions
      const competitionIds = []; // While we are at it, get a list of competition ids to use later
      payload.competitions.forEach(competition => {
        const id = competition.get("id");
        updated = updated.setIn(["competitions", id], competition);
        competitionIds.push(id);
      });

      // Prune old cached find requests
      const findRequests = updated.get("findRequests").toJS();
      const currentTime = new Date().getTime();
      Object.keys(findRequests).forEach(findRequestKey => {
        const findRequestData = findRequests[findRequestKey];
        if (currentTime - findRequestData.fetchTime > CACHE_EXPIRATION) {
          delete findRequests[findRequestKey];
        }
      });

      // Store the new find request
      findRequests[payload.findRequestKey] = {
        competitionIds,
        fetchTime: currentTime,
        totalCount: payload.meta.totalCount
      };

      // Update state with find request changes
      updated = updated.set("findRequests", fromJS(findRequests));

      return updated;
    }
  },
  defaultState
);

/**
 * Returns the competitions in an order based on the provided find request.
 * @param {Immutable.Map} state
 * @param {CompetitionFindRequest} [findRequest]
 * @return {Competition[]|null}
 */
export const getCompetitions = (state, findRequest) => {
  const competitionsState = state.get("competitions");

  // When a find request is used to get competitions, we find the specific set
  // of competitions that are tied to that find request and return them.
  if (findRequest) {
    const findRequestKey = findRequest.get("key");

    // If the find request doesn't exist or is expired, return null so the
    // consumer knows to fetch the data.
    const findRequestData = competitionsState.getIn([
      "findRequests",
      findRequestKey
    ]);
    if (
      !findRequestData ||
      new Date().getTime() - findRequestData.get("fetchTime") > CACHE_EXPIRATION
    ) {
      return null;
    }

    // Get the relevant competitions
    return (
      findRequestData
        .get("competitionIds")
        // Get each competition
        .map(competitionId =>
          competitionsState.getIn(["competitions", competitionId])
        )
        // Filter out those that may have been deleted or something
        .filter(competition => !!competition)
        .toJS()
    );
  }

  // If no find request is provided, just return what we have
  const competitionsMap = competitionsState.get("competitions");
  const competitionsArray = competitionsMap.valueSeq().toJS();
  return competitionsArray;
};

/**
 * Returns the competition based on competition id.
 * @param  {Immutable.Map} state
 * @param  {String} competitionId
 * @return {Competition|null}
 */
export const getCompetition = (state, competitionId) => {
  const competition = state.getIn([
    "competitions",
    "competitions",
    competitionId
  ]);
  // If there is no competition, or the competition is stale, return null so the consumer
  // can fetch it.
  if (!competition || competition.get("isStale")) {
    return null;
  }
  return competition;
};
