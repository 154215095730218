import { combineReducers } from "redux-immutable";
import CompetitionsReducer from "./competitions";
import CompetitorsReducer from "./competitors";
import EventsReducer from "./events";
import ScoresReducer from "./scores";
import TeamsReducer from "./teams";
import UserReducer from "./user";

/**
 * The global combined reducer
 */
export default combineReducers({
  competitions: CompetitionsReducer,
  competitors: CompetitorsReducer,
  events: EventsReducer,
  scores: ScoresReducer,
  teams: TeamsReducer,
  user: UserReducer
});
