import { fromJS } from "immutable";
import { handleActions } from "redux-actions";
import { CACHE_EXPIRATION } from "../../models/Team";
import {
  CREATE_TEAM,
  GET_TEAM,
  GET_TEAMS,
  GET_COMPETITIONS,
  GET_SCORES
} from "../actions";

export const defaultState = fromJS({
  teams: {},
  findRequests: {}
});

function handleIncomingTeams(state, action, isDirectRequest) {
  const { payload } = action;

  // Do nothing if there are no teams
  if (!payload.teams) {
    return state;
  }

  // start building state
  let updated = state;

  // Store the teams
  const teamIds = []; // Build up an array of ids for later
  payload.teams.forEach(team => {
    const id = team.get("id");
    updated = updated.setIn(["teams", id], team);
    teamIds.push(id);
  });

  // Prune old cached find requests
  const findRequests = updated.get("findRequests").toJS();
  const currentTime = new Date().getTime();
  Object.keys(findRequests).forEach(findRequestKey => {
    const findRequestData = findRequests[findRequestKey];
    if (currentTime - findRequestData.fetchTime > CACHE_EXPIRATION) {
      delete findRequests[findRequestKey];
    }
  });

  // Store the new find request
  findRequests[payload.findRequestKey] = {
    teamIds,
    fetchTime: currentTime,
    totalCount: isDirectRequest ? payload.meta.totalCount : null
  };

  // Update state with find request changes
  updated = updated.set("findRequests", fromJS(findRequests));

  return updated;
}

/**
 * The reducer managing data about the Teams.
 * Maintains an id-indexed map of teams as well as data about specific requests.
 */
export default handleActions(
  {
    [CREATE_TEAM.RECEIVED]: (state, action) => {
      // Since we created a team, we need to clear out any list caches since
      // the new team may need to be included in them.
      const updated = state.set("findRequests", fromJS({}));
      return updated.setIn(["teams", action.payload.get("id")], action.payload);
    },
    [GET_TEAM.RECEIVED]: (state, action) => {
      return state.setIn(["teams", action.payload.get("id")], action.payload);
    },
    [GET_TEAMS.RECEIVED]: (state, action) => {
      return handleIncomingTeams(state, action, true);
    },
    // Teams can be side-loaded when getting competitions
    [GET_COMPETITIONS.RECEIVED]: (state, action) => {
      return handleIncomingTeams(state, action);
    },
    // Teams can be side-loaded when getting scores
    [GET_SCORES.RECEIVED]: (state, action) => {
      return handleIncomingTeams(state, action);
    }
  },
  defaultState
);

/**
 * Gets teams based on the provided find request.
 *
 * Accepts CompetitionFindReqeust in case teams were sidelaoded as a part of
 * that request.
 *
 * @param  {Immutable.Map} state
 * @param  {CompetitionFindReqest} findRequest
 * @return {Team[]|null}
 */
export const getTeams = (state, findRequest) => {
  const teamsState = state.get("teams");

  // When a find request is used to get teams, we find the specific set
  // of teams that are tied to that find request and return them.
  if (findRequest) {
    const findRequestKey = findRequest.get("key");

    // If the find request doesn't exist or is expired, return null so the
    // consumer knows to fetch the data.
    const findRequestData = teamsState.getIn(["findRequests", findRequestKey]);
    if (
      !findRequestData ||
      new Date().getTime() - findRequestData.get("fetchTime") > CACHE_EXPIRATION
    ) {
      return null;
    }

    // Get the relevant teams
    return (
      findRequestData
        .get("teamIds")
        // Get each team
        .map(teamId => teamsState.getIn(["teams", teamId]))
        // Filter out those that may have been deleted or something
        .filter(team => !!team)
        .toJS()
    );
  }

  // If no find request is provided, just return what we have
  const teamsMap = teamsState.get("teams");
  const teamsArray = teamsMap.valueSeq().toJS();
  return teamsArray;
};

/**
 * Gets teams based on the provided find request.
 *
 * Accepts CompetitionFindReqeust in case teams were sidelaoded as a part of
 * that request.
 *
 * @param  {Immutable.Map} state
 * @param  {CompetitionFindReqest} [findRequest]
 * @return {Object<Team>|null}
 */
export const getTeamsIndexedById = (state, findRequest) => {
  const teams = getTeams(state, findRequest);
  if (!teams) {
    return teams;
  }
  return teams.reduce((accumulated, team) => {
    accumulated[team.get("id")] = team;
    return accumulated;
  }, {});
};

/**
 * Returns the team based on team id.
 * @param  {Immutable.Map} state
 * @param  {String} teamId
 * @return {Team|null}
 */
export const getTeam = (state, teamId) => {
  const team = state.getIn(["teams", "teams", teamId]);
  // If there is no team, or the team is stale, return null so the consumer
  // can fetch it.
  if (!team || team.get("isStale")) {
    return null;
  }
  return team;
};
