import React from "react";
import PropTypes from "prop-types";
import EventFindRequest from "../../models/findRequests/EventFindRequest";
import EventsContainer from "../EventsContainer";
import UserContainer from "../UserContainer";

/**
 * A container for coordinating data for the Events page.
 */
export default class EventsPageContainer extends React.Component {
  static propTypes = {
    /**
     * A function which will be provided data:
     * {
     *   sort(),
     *   refresh(),
     *   events,
     *   isLoading
     * }
     */
    children: PropTypes.func.isRequired
  };

  state = {
    findRequest: new EventFindRequest()
  };

  // Updates the find request to sort the data based on the provided metric
  sort = metric => {
    const updatedRequest = this.state.findRequest.set("orderBy", metric);
    this.setState({
      findRequest: updatedRequest
    });
  };

  render() {
    return (
      <EventsContainer findRequest={this.state.findRequest}>
        {eventsData => (
          <UserContainer>
            {userData =>
              this.props.children({
                userData,
                eventsData,
                sort: this.sort
              })
            }
          </UserContainer>
        )}
      </EventsContainer>
    );
  }
}
