import React from "react";
import PropTypes from "prop-types";
import { Button, ModalConfirm } from "react-watty-ui";
import Score from "../../models/Score";
import UserContainer from "../../containers/UserContainer";
import ScoreContainer from "../../containers/ScoreContainer";

/**
 * A button to delete a score.
 */
const DeleteScoreButton = ({ score, ...props }) => (
  <UserContainer>
    {({ isAdmin }) => (
      <ScoreContainer scoreId={score.get("id")}>
        {scoreData => (
          <ModalConfirm
            onConfirm={scoreData.delete}
            message="Are you sure you want to delete this score?"
          >
            {modal => {
              if (!isAdmin) {
                return null;
              }
              return (
                <Button
                  data-test-id="delete-button"
                  disabled={scoreData.isDeleting}
                  onClick={modal.actions.open}
                  {...props}
                >
                  Delete
                </Button>
              );
            }}
          </ModalConfirm>
        )}
      </ScoreContainer>
    )}
  </UserContainer>
);

DeleteScoreButton.propTypes = {
  /** The score to be deleted */
  score: PropTypes.instanceOf(Score)
};

export default DeleteScoreButton;
