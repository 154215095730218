import React from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import { Spacer, H1, P, A, Tile, Label, Input, Button } from "react-watty-ui";
import getQueryParameter from "../../utils/getQueryParameter";
import UserContainer from "../../containers/UserContainer";
import LogInFormContainer from "../../containers/LogInFormContainer";
import PageWrapper from "../PageWrapper";

export const StyledTile = styled(Tile)`
  max-width: 500px;
  margin: 0 auto;
  padding: 16px;
  ${({ theme }) => theme.patterns.clearfix};
`;

export const SubmitButton = styled(Button)`
  float: right;
`;

const LogIn = () => {
  return (
    <PageWrapper>
      <UserContainer>
        {user =>
          user.isLoggedIn ? (
            <Redirect to={getQueryParameter("returnPath") || "/"} />
          ) : null
        }
      </UserContainer>
      <H1>Log In</H1>
      <Spacer as="div" top="md" />
      <P>
        Log in using your AKA account. This is the same login that you would use
        to access{" "}
        <A href="https://members.kite.org" target="_blank">
          members.kite.org
        </A>
        .
      </P>
      <Spacer as="div" top="lg" />

      <LogInFormContainer>
        {logInForm => (
          <StyledTile
            as="form"
            onSubmit={e => {
              e.preventDefault();
              logInForm.submit();
            }}
          >
            <Label>Email Address</Label>
            <Input
              type="email"
              value={logInForm.emailAddress}
              required
              onChange={e => logInForm.setEmail(e.target.value)}
              disabled={!!logInForm.isLoggingIn}
              data-test-id="email"
            />
            <Spacer as="div" top="md" />
            <Label>Password</Label>
            <Input
              type="password"
              required
              value={logInForm.password}
              onChange={e => logInForm.setPassword(e.target.value)}
              disabled={!!logInForm.isLoggingIn}
              data-test-id="password"
            />
            <Spacer as="div" top="md" />
            <SubmitButton
              isPrimary
              type="submit"
              disabled={!!logInForm.isLoggingIn}
            >
              {logInForm.isLoggingIn ? "Submitting" : "Submit"}
            </SubmitButton>
          </StyledTile>
        )}
      </LogInFormContainer>
    </PageWrapper>
  );
};

export default LogIn;
