import PropTypes from "prop-types";
import { computed, createModel } from "manikin-model";
import { computeKey, computeJson, computePayload } from "./util";

/**
 * A find request for fetching Competitions
 */
const CompetitionFindRequest = createModel("CompetitionFindRequest", {
  eventIdList: [],
  eventIdNotList: [],
  limit: 100,
  skip: 0,
  orderBy: "date",
  orderDirection: "descending",
  include: [],
  key: computed(function() {
    return computeKey.call(this);
  }),
  json: computed(function() {
    return computeJson.call(
      this,
      "eventIdList",
      "eventIdNotList",
      "limit",
      "skip",
      "orderBy",
      "orderDirection",
      "include"
    );
  }),
  payload: computed(function() {
    return computePayload.call(this);
  })
});

CompetitionFindRequest.propTypes = {
  // A list of event ids to include competitions for
  eventIdList: PropTypes.arrayOf(PropTypes.string),
  // A list of event ids to exclude competitions for
  eventIdNotList: PropTypes.arrayOf(PropTypes.string),
  // The maximum number of records to return in one request
  limit: PropTypes.number,
  // The number of records to skip in the request; for pagination
  skip: PropTypes.number,
  // The property to sort the events by
  orderBy: PropTypes.oneOf([
    "id",
    "eventId",
    "discipline",
    "competitionType",
    "level",
    "date",
    "category"
  ]),
  // The direction in which to sort the events
  orderDirection: PropTypes.oneOf(["ascending", "descending"]),
  // Data to be sideloaded
  include: PropTypes.arrayOf(
    PropTypes.oneOf(["scores", "scores_competitors", "scores_team"])
  ),
  // A unique key that can be used to reference this request for cacheing
  key: PropTypes.string,
  /**
   * The find request details condensed to raw json.
   */
  json: PropTypes.shape({
    // include, eventIdList and eventIdNotList should be converted to comma
    // separated values
    include: PropTypes.string,
    eventIdList: PropTypes.string,
    eventIdNotList: PropTypes.string
  }),
  // The payload to be used to communicate with the server. Same as the `json`
  // property but with empty values filtered out.
  payload: PropTypes.shape({
    // include, eventIdList and eventIdNotList should be converted to comma
    // separated values
    include: PropTypes.string,
    eventIdList: PropTypes.string,
    eventIdNotList: PropTypes.string
  })
};

export default CompetitionFindRequest;
