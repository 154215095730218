import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { Spacer } from "react-watty-ui";
import CombineContainers from "../../containers/CombineContainers";
import ScoreCreateContainer from "../../containers/ScoreCreateContainer";
import UserContainer from "../../containers/UserContainer";
import ErrorPage from "../ErrorPage";
import PageWrapper from "../PageWrapper";
import NewScoreHeading from "./NewScoreHeading";
import NewScoreForm from "./NewScoreForm";

/**
 * The page wrapper for the scores/new page.
 */
const NewScore = ({ match }) => {
  const eventId = match.params.eventId;
  const competitionId = match.params.competitionId;
  return (
    <CombineContainers
      containers={[
        {
          component: UserContainer,
          reference: "userData"
        },
        {
          component: ScoreCreateContainer,
          reference: "createData"
        }
      ]}
    >
      {({ userData, createData }) => {
        // Redirect to login if the user is not logged in.
        if (!userData.isLoggedIn) {
          return (
            <Redirect
              to={`/login?returnPath=/events/${eventId}/competitions/${competitionId}/scores/new`}
            />
          );
        }

        // Show 403 if the user is not an admin.
        if (!userData.user.get("isAdmin")) {
          return (
            <ErrorPage
              errorCode={403}
              errorMessage="You don't have permission to view this page."
            />
          );
        }
        return (
          <PageWrapper>
            <NewScoreHeading eventId={eventId} competitionId={competitionId} />
            <Spacer bottom="lg" as="div" />
            <NewScoreForm
              onSubmit={createData.saveScore}
              isDisabled={createData.isLoading}
              competitionId={competitionId}
            />
            {createData.savedScore && (
              <Redirect
                to={`/events/${eventId}/competitions/${competitionId}`}
              />
            )}
          </PageWrapper>
        );
      }}
    </CombineContainers>
  );
};

NewScore.propTypes = {
  /**
   * Information provided by react router.
   */
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventId: PropTypes.string.isRequired,
      competitionId: PropTypes.string.isRequired
    })
  })
};

export default NewScore;
