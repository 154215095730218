// import { createAction } from "redux-actions";
import CompetitionApi from "../api/CompetitionApi";
import createAsyncAction from "./createAsyncAction";

/**
 * Check if the user is already logged in.
 */
export const CHECK_LOGIN = createAsyncAction("CHECK_LOGIN", () => {
  return CompetitionApi.checkLoginStatus();
});

/**
 * Save a competition to the server.
 */
export const CREATE_COMPETITION = createAsyncAction(
  "CREATE_COMPETITION",
  competition => {
    return CompetitionApi.createCompetition(competition);
  }
);

/**
 * Save a competitor to the server.
 */
export const CREATE_COMPETITOR = createAsyncAction(
  "CREATE_COMPETITOR",
  competitor => {
    return CompetitionApi.createCompetitor(competitor);
  }
);

/**
 * Save an event to the server.
 */
export const CREATE_EVENT = createAsyncAction("CREATE_EVENT", event => {
  return CompetitionApi.createEvent(event);
});

/**
 * Save a score to the server.
 */
export const CREATE_SCORE = createAsyncAction("CREATE_SCORE", score => {
  return CompetitionApi.createScore(score);
});

/**
 * Save a team to the server.
 */
export const CREATE_TEAM = createAsyncAction("CREATE_TEAM", team => {
  return CompetitionApi.createTeam(team);
});

/**
 * Deletes a competition from the server
 */
export const DELETE_COMPETITION = createAsyncAction(
  "DELETE_COMPETITION",
  competition => {
    return CompetitionApi.deleteCompetition(competition);
  }
);

/**
 * Deletes an event from the server
 */
export const DELETE_EVENT = createAsyncAction("DELETE_EVENT", event => {
  return CompetitionApi.deleteEvent(event);
});

/**
 * Deletes a score from the server
 */
export const DELETE_SCORE = createAsyncAction("DELETE_SCORE", score => {
  return CompetitionApi.deleteScore(score);
});

/**
 * Get a competition based on the competitionId
 */
export const GET_COMPETITION = createAsyncAction(
  "GET_COMPETITION",
  competitionId => {
    return CompetitionApi.getCompetition(competitionId);
  }
);

/**
 * Get competitions based on the competitionFindRequest.
 */
export const GET_COMPETITIONS = createAsyncAction(
  "GET_COMPETITIONS",
  competitionFindRequest => {
    return CompetitionApi.getCompetitions(competitionFindRequest);
  }
);

/**
 * Get a competitor based on the competitorId
 */
export const GET_COMPETITOR = createAsyncAction(
  "GET_COMPETITOR",
  competitorId => {
    return CompetitionApi.getCompetitor(competitorId);
  }
);

/**
 * Get competitors based on the competitorFindRequest.
 */
export const GET_COMPETITORS = createAsyncAction(
  "GET_COMPETITORS",
  competitorFindRequest => {
    return CompetitionApi.getCompetitors(competitorFindRequest);
  }
);

/**
 * Get an event based on the eventId
 */
export const GET_EVENT = createAsyncAction("GET_EVENT", eventId => {
  return CompetitionApi.getEvent(eventId);
});

/**
 * Get events based on the eventFindRequest.
 */
export const GET_EVENTS = createAsyncAction("GET_EVENTS", eventFindRequest => {
  return CompetitionApi.getEvents(eventFindRequest);
});

/**
 * Get a score based on the scoreId
 */
export const GET_SCORE = createAsyncAction("GET_SCORE", scoreId => {
  return CompetitionApi.getScore(scoreId);
});

/**
 * Get scores based on the scoreFindRequest.
 */
export const GET_SCORES = createAsyncAction("GET_SCORES", scoreFindRequest => {
  return CompetitionApi.getScores(scoreFindRequest);
});

/**
 * Get a team based on the teamId
 */
export const GET_TEAM = createAsyncAction("GET_TEAM", teamId => {
  return CompetitionApi.getTeam(teamId);
});

/**
 * Get teams based on the teamFindRequest.
 */
export const GET_TEAMS = createAsyncAction("GET_TEAMS", teamFindRequest => {
  return CompetitionApi.getTeams(teamFindRequest);
});

/**
 * Log in using email and password
 */
export const LOG_IN = createAsyncAction("LOG_IN", (email, password) => {
  return CompetitionApi.logIn(email, password);
});

/**
 * Log out.
 */
export const LOG_OUT = createAsyncAction("LOG_OUT", () => {
  return CompetitionApi.logOut();
});
