import React from "react";

/**
 * A reusable background for the first/second/third place illustrations.
 * Not intended to be used on its own.
 */
const PlaceGarnish = props => (
  <g fill="#f00" {...props}>
    <path d="m1246.9 184.3c0.2 11.2-0.3 22.4-1 33.6s-1.7 22.4-2.9 33.6c-2.5 22.3-6 44.6-10.4 66.6-4.5 22.1-9.8 44-16 65.6-6.3 21.7-13.4 43.1-21.5 64.2-16.2 42.2-36.1 83-59.7 121.8-23.7 38.7-51.1 75.3-81.8 109-15.4 16.8-31.7 32.8-48.6 48.2-17 15.3-34.8 29.6-53.1 43.3l-13.9 10-3.5 2.5-3.6 2.4-7.1 4.8c-9.4 6.5-19.2 12.4-28.9 18.5-4.9 3-9.9 5.8-14.8 8.7l-7.4 4.3-7.5 4.1-15.1 8.2-15.3 7.7-7.7 3.9-7.8 3.6-15.6 7.2c-5.2 2.3-10.5 4.5-15.8 6.8-5.3 2.2-10.5 4.6-15.9 6.6-10.7 4.1-21.3 8.5-32.1 12.2l-16.2 5.7-16.3 5.3c-43.6 13.9-88.1 24.9-133 33.3-12.3 2.3-24.2-5.8-26.5-18.2-2.3-12.3 5.8-24.2 18.2-26.5 0.3-0.1 0.7-0.1 0.9-0.2 43.6-6.2 86.7-15 129.1-26.6l15.9-4.4 15.7-4.9c10.5-3.1 20.9-6.8 31.3-10.3 5.2-1.7 10.3-3.8 15.5-5.7 5.1-2 10.3-3.8 15.4-5.9l15.2-6.3c2.5-1.1 5.1-2.1 7.6-3.2l7.5-3.4 15-6.8 14.8-7.2 7.4-3.6 7.3-3.9c4.8-2.6 9.7-5.1 14.5-7.7 9.5-5.5 19.2-10.7 28.4-16.6l7-4.3 3.5-2.2 3.4-2.3 13.8-9.1c18.1-12.4 35.8-25.5 52.8-39.5 16.9-14.1 33.2-28.9 48.8-44.5s30.3-32.1 44.3-49.2 27.2-34.9 39.5-53.3c24.6-36.9 45.8-76.1 63.5-116.9 8.8-20.4 16.8-41.3 23.9-62.4 7.1-21.2 13.2-42.6 18.6-64.3 5.3-21.7 9.7-43.7 13.2-65.8 1.7-11.1 3.2-22.2 4.3-33.3 1.5-10.8 2.4-22 2.7-33.2z" />
    <path d="m1250 27.8s-127.6 198.3-7.1 202.5c123.9 4.3 7.1-202.5 7.1-202.5z" />
    <path d="m1339.2 268.6s-252.7 40.8-168.7 141.2c86.4 103.2 168.7-141.2 168.7-141.2z" />
    <path d="m1152.4 217.5s-47.6 251.5 75.3 206.7c126.5-46.1-75.3-206.7-75.3-206.7z" />
    <path d="m1292.6 546.3s-306.5-79.5-261.4 76c46.5 159.9 261.4-76 261.4-76z" />
    <path d="m1049.1 395s-100.2 300.4 58 265.9c162.7-35.4-58-265.9-58-265.9z" />
    <path d="m1137.8 863.7s-313.5-292.2-360-78c-47.7 220.2 360 78 360 78z" />
    <path d="m874.8 530.5s-215.1 370.7 4 368.3c225.4-2.5-4-368.3-4-368.3z" />
    <path d="m104.2 168.5c0.1 11.2 0.8 22.4 1.8 33.6s2.2 22.3 3.7 33.4c3 22.2 7.1 44.2 12 66s10.7 43.4 17.4 64.7 14.3 42.2 22.8 62.8c16.9 41.2 37.5 80.8 61.4 118.1 12 18.6 24.9 36.6 38.6 54 13.7 17.3 28.1 34.1 43.4 50s31.4 31 48 45.4c16.7 14.3 34.2 27.7 52.1 40.5l13.6 9.3 3.4 2.3 3.5 2.2 6.9 4.4c9.2 6 18.7 11.5 28.1 17.1 4.8 2.7 9.6 5.3 14.4 8l7.2 4 7.3 3.8 14.7 7.5 14.9 7 7.4 3.5 7.5 3.3 15.1 6.6c5.1 2.1 10.2 4.1 15.3 6.1s10.2 4.1 15.4 5.9c10.3 3.7 20.6 7.6 31.1 10.9l15.8 5.1 15.8 4.7c42.2 12.4 85.2 21.9 128.6 28.9 12.4 2 20.8 13.7 18.8 26-2 12.4-13.7 20.8-26 18.8-0.3 0-0.7-0.1-0.9-0.2-44.8-9.2-89.1-21-132.4-35.7l-16.2-5.6-16.1-6c-10.8-3.9-21.3-8.4-31.9-12.7-5.3-2.1-10.5-4.6-15.7-6.9-5.2-2.4-10.5-4.6-15.6-7.1l-15.4-7.5c-2.6-1.3-5.2-2.5-7.7-3.8l-7.6-4-15.2-8-14.9-8.5-7.5-4.2-7.3-4.5c-4.9-3-9.8-5.9-14.6-9-9.6-6.3-19.3-12.4-28.5-19.1l-7-4.9-3.5-2.5-3.4-2.6-13.7-10.3c-18-14-35.6-28.7-52.3-44.3-16.6-15.6-32.6-32-47.7-49-30.1-34.2-56.9-71.3-79.8-110.4-22.9-39.2-42.1-80.4-57.5-122.9-7.7-21.2-14.5-42.8-20.4-64.5-5.8-21.8-10.8-43.8-14.8-65.9s-7.1-44.4-9.2-66.8c-1-11.2-1.8-22.4-2.3-33.6-1-11-1.2-22.2-0.9-33.4z" />
    <path d="M104,12c0,0,124,200.6,3.4,202.6C-16.5,216.7,104,12,104,12z" />
    <path d="M10.5,251.1c0,0,251.9,45.4,166.1,144.2C88.4,497,10.5,251.1,10.5,251.1z" />
    <path d="m198.1 203.4s43.1 252.3-79 205.3c-125.6-48.4 79-205.3 79-205.3z" />
    <path d="m52.1 529.6s307.9-74 259.9 80.7c-49.3 159-259.9-80.7-259.9-80.7z" />
    <path d="m298.3 382.8s94.8 302.2-62.8 264.8c-162.1-38.4 62.8-264.8 62.8-264.8z" />
    <path d="m201.1 849.7s318.8-286.4 361.3-71.5c43.7 221.1-361.3 71.5-361.3 71.5z" />
    <path d="m470 521.4s208.3 374.5-10.7 368.2c-225.2-6.6 10.7-368.2 10.7-368.2z" />
  </g>
);

export default PlaceGarnish;
