import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Spacer, Label, Input, Error, P, Button } from "react-watty-ui";
import Competitor from "../../models/Competitor";

const ButtonWrapper = styled.div`
  text-align: right;
`;

function getErrors(data) {
  const errors = {};
  if (!data.name) {
    errors.name = "A name must be provided.";
  }
  if (!data.akaMemberId) {
    errors.akaMemberId = "An AKA Member ID must be provided.";
  }

  return errors;
}

/**
 * A form for creating a new Competitor
 */
const NewCompetitorForm = ({ competitorName, onSubmit, isDisabled }) => {
  const [name, setName] = useState(competitorName || "");
  const [akaMemberId, setMemberId] = useState("");
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const errors = getErrors({
    name,
    akaMemberId
  });
  const hasErrors = Object.keys(errors).length > 0;

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        if (isDisabled) {
          return;
        }
        setSubmitAttempted(true);
        if (hasErrors) {
          return;
        }

        const competitorObject = new Competitor({
          name,
          akaMemberId
        });
        onSubmit(competitorObject);
      }}
    >
      <Label>Name</Label>
      <Input
        data-test-id="name-field"
        value={name}
        onChange={e => setName(e.target.value)}
      />
      {submitAttempted && errors.name && (
        <Error data-test-id="name-error">
          <P>{errors.name}</P>
        </Error>
      )}
      <Spacer bottom="md" as="div" />

      <Label>AKA Member ID</Label>
      <Input
        data-test-id="akaMemberId-field"
        value={akaMemberId}
        onChange={e => setMemberId(e.target.value)}
      />
      {submitAttempted && errors.akaMemberId && (
        <Error data-test-id="akaMemberId-error">
          <P>{errors.akaMemberId}</P>
        </Error>
      )}
      <Spacer bottom="md" as="div" />

      <ButtonWrapper>
        <Button type="submit" isPrimary disabled={isDisabled}>
          Submit
        </Button>
      </ButtonWrapper>
    </form>
  );
};

NewCompetitorForm.propTypes = {
  /** A prefilled value for the competitor name */
  competitorName: PropTypes.string,
  /** A function called when the form is submitted. Is provided with the Competitor to be saved */
  onSubmit: PropTypes.func.isRequired,
  /** Disables the form and prevens submission if true. */
  isDisabled: PropTypes.bool
};

export default NewCompetitorForm;
