import axios from "axios";
import checkLoginStatus from "./competition/checkLoginStatus";
import createCompetition from "./competition/createCompetition";
import createCompetitor from "./competition/createCompetitor";
import createEvent from "./competition/createEvent";
import createScore from "./competition/createScore";
import createTeam from "./competition/createTeam";
import deleteCompetition from "./competition/deleteCompetition";
import deleteEvent from "./competition/deleteEvent";
import deleteScore from "./competition/deleteScore";
import getCompetition from "./competition/getCompetition";
import getCompetitions from "./competition/getCompetitions";
import getCompetitor from "./competition/getCompetitor";
import getCompetitors from "./competition/getCompetitors";
import getEvent from "./competition/getEvent";
import getEvents from "./competition/getEvents";
import getScore from "./competition/getScore";
import getScores from "./competition/getScores";
import getTeam from "./competition/getTeam";
import getTeams from "./competition/getTeams";
import logIn from "./competition/logIn";
import logOut from "./competition/logOut";

export class CompetitionApi {
  constructor() {
    this.config = {
      baseURL: this.baseUrl,
      headers: {
        Accept: "application/json"
      }
    };

    this.axiosInstance = axios.create(this.config);
  }

  /**
   * The base url for all API requests
   * @type {String}
   */
  baseUrl = process.env.REACT_APP_API_BASE;

  /**
   * The axios configuration.
   * @type {Object}
   */
  config = {};

  /**
   * The axios instance.
   * @type {Axios}
   */
  axiosInstance = null;

  /**
   * Check if the user is already logged in
   * @return {Promise}
   */
  checkLoginStatus() {
    return checkLoginStatus.call(this);
  }

  /**
   * Create an competition on the server.
   * @param  {Competition} competition The competition to save
   * @return {Promise<Competition>} The saved competition
   */
  createCompetition(competition) {
    return createCompetition.call(this, competition);
  }

  /**
   * Create an competitor on the server.
   * @param  {Competitor} competitor The competitor to save
   * @return {Promise<Competitor>} The saved competitor
   */
  createCompetitor(competitor) {
    return createCompetitor.call(this, competitor);
  }

  /**
   * Create an event on the server.
   * @param  {Event} event The event to save
   * @return {Promise<Event>} The saved event
   */
  createEvent(event) {
    return createEvent.call(this, event);
  }

  /**
   * Create a score on the server.
   * @param  {Score} score The score to save
   * @return {Promise<Score>} The saved score
   */
  createScore(score) {
    return createScore.call(this, score);
  }

  /**
   * Create a team on the server.
   * @param  {Team} team The team to save
   * @return {Promise<Team>} The saved team
   */
  createTeam(team) {
    return createTeam.call(this, team);
  }

  /**
   * Delete an competition from the server
   * @param  {Competition} competition The competition to delete
   * @return {Promise<String>} Resolves to the id of the competition that was deleted
   */
  deleteCompetition(competition) {
    return deleteCompetition.call(this, competition);
  }

  /**
   * Delete an event from the server
   * @param  {Event} event The event to delete
   * @return {Promise<String>} Resolves to the id of the event that was deleted
   */
  deleteEvent(event) {
    return deleteEvent.call(this, event);
  }

  /**
   * Delete an score from the server
   * @param  {Score} score The score to delete
   * @return {Promise<String>} Resolves to the id of the score that was deleted
   */
  deleteScore(score) {
    return deleteScore.call(this, score);
  }

  /**
   * Get a competition based on the provided competitionId
   * @param  {String} competitionId
   * @return {Promise<Competition>}
   */
  getCompetition(competitionId) {
    return getCompetition.call(this, competitionId);
  }

  /**
   * Get competitions based on the provided EventFindRequest
   * @param  {CompetitionFindRequest} competitionFindRequest
   * @return {Promise<Object>} resolves to { competitions: Competition[], meta: object }
   */
  getCompetitions(competitionFindRequest) {
    return getCompetitions.call(this, competitionFindRequest);
  }

  /**
   * Get a competitor based on the provided competitorId
   * @param  {String} competitorId
   * @return {Promise<Competitor>}
   */
  getCompetitor(competitorId) {
    return getCompetitor.call(this, competitorId);
  }

  /**
   * Get competitors based on the provided EventFindRequest
   * @param  {CompetitorFindRequest} competitorFindRequest
   * @return {Promise<Object>} resolves to { competitors: Competitor[], meta: object }
   */
  getCompetitors(competitorFindRequest) {
    return getCompetitors.call(this, competitorFindRequest);
  }

  /**
   * Get an event based on the provided eventId
   * @param  {String} eventId
   * @return {Promise<Event>}
   */
  getEvent(eventId) {
    return getEvent.call(this, eventId);
  }

  /**
   * Get events based on the provided EventFindRequest
   * @param  {EventFindRequest} eventFindRequest
   * @return {Promise<Object>} resolves to { events: Event[], meta: object }
   */
  getEvents(eventFindRequest) {
    return getEvents.call(this, eventFindRequest);
  }

  /**
   * Get a score based on the provided scoreId
   * @param  {String} scoreId
   * @return {Promise<Score>}
   */
  getScore(scoreId) {
    return getScore.call(this, scoreId);
  }

  /**
   * Get scores based on the provided ScoreFindRequest
   * @param  {ScoreFindRequest} scoreFindRequest
   * @return {Promise<Object>} resolves to { scores: Score[], meta: object }
   */
  getScores(scoreFindRequest) {
    return getScores.call(this, scoreFindRequest);
  }

  /**
   * Get a team based on the provided teamId
   * @param  {String} teamId
   * @return {Promise<Competitor>}
   */
  getTeam(teamId) {
    return getTeam.call(this, teamId);
  }

  /**
   * Get teams based on the provided EventFindRequest
   * @param  {TeamFindRequest} teamFindRequest
   * @return {Promise<Object>} resolves to { teams: Team[], meta: object }
   */
  getTeams(teamFindRequest) {
    return getTeams.call(this, teamFindRequest);
  }

  /**
   * Log in using email and password.
   * @param {String} email
   * @param {String} password
   * @return {Promise}
   */
  logIn(email, password) {
    return logIn.call(this, email, password);
  }

  /**
   * Log out.
   * @return {Promise}
   */
  logOut() {
    return logOut.call(this);
  }
}

export default new CompetitionApi();
