import PropTypes from "prop-types";
import { computed, createModel } from "manikin-model";
import { computeKey, computeJson, computePayload } from "./util";

/**
 * A find request for fetching Competitors
 */
const CompetitorFindRequest = createModel("CompetitorFindRequest", {
  idList: [],
  idNotList: [],
  nameContains: null,
  limit: 100,
  skip: 0,
  orderBy: "name",
  orderDirection: "ascending",
  key: computed(function() {
    return computeKey.call(this);
  }),
  json: computed(function() {
    return computeJson.call(
      this,
      "idList",
      "idNotList",
      "nameContains",
      "limit",
      "skip",
      "orderBy",
      "orderDirection"
    );
  }),
  payload: computed(function() {
    return computePayload.call(this);
  })
});

CompetitorFindRequest.propTypes = {
  // A list of ids to include competitors for
  idList: PropTypes.arrayOf(PropTypes.string),
  // A list of ids to exclude competitors for
  idNotList: PropTypes.arrayOf(PropTypes.string),
  // A seach string to return competitors based on their name
  nameContains: PropTypes.string,
  // The maximum number of records to return in one request
  limit: PropTypes.number,
  // The number of records to skip in the request; for pagination
  skip: PropTypes.number,
  // The property to sort the events by
  orderBy: PropTypes.oneOf(["id", "name"]),
  // The direction in which to sort the events
  orderDirection: PropTypes.oneOf(["ascending", "descending"]),
  // A unique key that can be used to reference this request for cacheing
  key: PropTypes.string,
  /**
   * The find request details condensed to raw json.
   */
  json: PropTypes.shape({
    // idList and idNotList should be converted to comma separated values
    idList: PropTypes.string,
    idNotList: PropTypes.string
  }),
  // The payload to be used to communicate with the server. Same as the `json`
  // property but with empty values filtered out.
  payload: PropTypes.shape({
    // idList and idNotList should be converted to comma separated values
    idList: PropTypes.string,
    idNotList: PropTypes.string
  })
};

export default CompetitorFindRequest;
