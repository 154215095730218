import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCompetition } from "../redux/modules/competitions";
import Competition from "../models/Competition";
import { DELETE_COMPETITION, GET_COMPETITION } from "../redux/actions";

/**
 * A container that fetches and provides data about a single competition
 */
export class CompetitionContainer extends React.Component {
  static propTypes = {
    /** A function called with data about the competition */
    children: PropTypes.func.isRequired,
    /** A function to call to fetch the competition from the API. Provided by Redux */
    onFetch: PropTypes.func.isRequired,
    /** The relevant competition. Provided by Redux. */
    competition: PropTypes.instanceOf(Competition),
    /** A function called when requesting to delete the competition */
    onDelete: PropTypes.func.isRequired,
    /** The ID of the competition to display */
    competitionId: PropTypes.string.isRequired
  };

  state = {
    isLoading: true,
    errorCode: null,
    errorMessage: null,
    isDeleting: false,
    isDeleted: false
  };

  componentDidMount() {
    // Fetch the competition if we don't have it when mounting
    if (!this.props.competition) {
      this.fetchCompetition();
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate(prevProps) {
    // If we change the competition id and don't have that competition, fetch it
    if (
      prevProps.competitionId !== this.props.competitionId &&
      !this.props.competition
    ) {
      this.fetchCompetition();
    }
  }

  /**
   * Fetches the competition and updates state's isLoading, errorCode, and errorMessage.
   */
  fetchCompetition = async () => {
    this.setState({
      isLoading: true,
      errorCode: null,
      errorMessage: null
    });
    await this.props.onFetch(this.props.competitionId).catch(e => {
      this.setState({
        errorCode: e.status,
        errorMessage: e.data.error
      });
    });
    this.setState({ isLoading: false });
  };

  /** Deletes the current competition */
  deleteCompetition = async () => {
    this.setState({
      isDeleting: true
    });
    await this.props.onDelete(this.props.competition).catch(e => {
      this.setState({
        isDeleting: false
      });
      return Promise.reject(e);
    });
    this.setState({
      isDeleting: false,
      isDeleted: true
    });
  };

  render() {
    return this.props.children({
      competition: this.props.competition,
      errorCode: this.state.errorCode,
      errorMessage: this.state.errorMessage,
      isLoading: this.state.isLoading,
      refresh: this.fetchCompetition,
      delete: this.deleteCompetition,
      isDeleting: this.state.isDeleting,
      isDeleted: this.state.isDeleted
    });
  }
}

const mapStateToProps = (state, props) => ({
  competition: getCompetition(state, props.competitionId)
});

const mapDispatchToProps = {
  onFetch: GET_COMPETITION,
  onDelete: DELETE_COMPETITION
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompetitionContainer);
