import React from "react";
import PlaceGarnish from "./PlaceGarnish";

/**
 * In illustration representing third place in a competition.
 */
const ThirdPlace = props => (
  <svg
    enableBackground="new 0 0 1350 1100"
    version="1.1"
    viewBox="0 0 1350 1100"
    {...props}
  >
    <PlaceGarnish />
    <path d="m531.7 60.3c24.3-13.7 55.2-20.5 92.8-20.5 26.7 0 52 3.1 75.9 9.2s44.8 15.4 62.6 27.7 31.8 28 42.1 47.2c10.2 19.2 15.4 42.1 15.4 68.7 0 24.6-4.5 46.7-13.3 66.2-8.9 19.5-20 36.2-33.3 50.3s-28.2 25.5-44.6 34.4-32.2 15.4-47.2 19.5v6.2c17.8 1.4 36.6 4.6 56.4 9.8 19.8 5.1 38.3 13.7 55.4 25.6 17.1 12 31.3 27.9 42.6 47.7s16.9 45.1 16.9 75.9c0 31.5-6.3 60.4-19 86.7s-30.5 49.1-53.4 68.2c-22.9 19.2-50.5 33.9-82.6 44.1-32.2 10.2-67.7 15.4-106.7 15.4-35.6 0-65.7-3.2-90.3-9.8s-46.2-14.2-64.6-23.1c2.7-5.5 5.3-14.5 7.7-27.2s3.9-24.1 4.6-34.4l6.2-4.1c6.2 6.8 13.7 14.4 22.6 22.6s19.3 15.7 31.3 22.6 25 12.5 39 16.9 29.2 6.7 45.7 6.7c26 0 49.1-4.1 69.3-12.3s37.4-19.8 51.8-34.9c14.4-15 25.3-33.2 32.8-54.4s11.3-44.5 11.3-69.8c0-28-3.6-51.8-10.8-71.3s-17.3-35.1-29.9-47.1-27.9-20.7-45.7-26.2-37.3-8.2-58.5-8.2c-7.5 0-15.6 0.7-24.1 2-8.5 1.4-15.9 2.7-22 4.1 0.7-3.4 1.2-7 1.5-10.8s0.5-7.3 0.5-10.8-0.2-7-0.5-10.8-0.8-7.3-1.5-10.8c13.7 2.7 29.1 4.1 46.2 4.1 23.9 0 43.4-5 58.5-14.9 15-9.9 26.8-22.6 35.4-38 8.5-15.4 14.4-32.3 17.4-50.8s4.6-36.2 4.6-53.3c0-21.9-3.2-40.9-9.8-57-6.5-16.1-14.9-29.2-25.1-39.5-10.3-10.2-21.9-17.8-34.9-22.6s-26.3-7.2-40-7.2c-28.7 0-53.2 8.4-73.4 25.1-20.2 16.8-34 35.8-41.6 56.9l-7.2 2-23.6-55.4c13.8-13.2 32.8-26.9 57.1-40.6z" />
    <path d="m960.9 119.2c-2.4-0.8-4.8-1.2-7.2-1.2-3.9 0-7.4 0.9-10.5 2.7s-5.7 4.2-7.9 7.2c-2.1 3-3.8 6.6-4.9 10.8s-1.7 8.6-1.7 13.4v27c0 11 0 19.3 0.1 25.1s0.4 10.5 0.9 14.1c-3.3-0.3-6.5-0.5-9.8-0.5-3.6 0-7 0.2-10.3 0.5 0.5-5.6 0.8-13.1 0.9-22.7s0.1-21.3 0.1-35.2v-7.9c0-6.4 0-13.7-0.1-22.1-0.1-8.3-0.4-17.9-0.9-28.7 1.8 0.3 3.5 0.6 5.2 0.9 1.6 0.2 3.4 0.4 5.2 0.4 1.5 0 3-0.1 4.7-0.4 1.6-0.2 3.4-0.5 5.2-0.9-0.7 5.9-1 10.5-1.1 14-0.1 3.4-0.1 6.7-0.1 9.8l0.5 0.2c9.6-17.7 20.4-26.5 32.4-26.5 1.5 0 2.7 0.1 3.8 0.2 1.1 0.2 2.4 0.6 4 1.2-0.8 2-1.3 4.9-1.5 9-0.2 4-0.2 7.6-0.2 10.9l-1.7 1.2c-1.2-0.8-2.8-1.7-5.1-2.5z" />
    <path d="m1074.1 195c-1.3 2.6-3.1 5.4-5.3 8.5-2.2 3-5 5.8-8.3 8.5-3.4 2.6-7.3 4.7-11.9 6.4-4.6 1.6-9.9 2.5-15.9 2.5-6.2 0-12.3-1.1-18.3-3.2s-11.3-5.6-15.9-10.4c-4.7-4.8-8.4-11-11.3-18.6s-4.3-16.7-4.3-27.4c0-10.5 1.6-19.5 4.7-27.2s7.2-14.1 12.1-19.3c5-5.2 10.6-9 16.9-11.5s12.6-3.8 19-3.8c18.2 0 30.7 8.2 37.8 24.5h0.5v-24c0-6.2 0-12.5-0.1-18.8s-0.2-12.3-0.2-18c-0.1-5.7-0.2-11.1-0.2-16.2-0.1-5.1-0.2-9.6-0.4-13.5 2 0.3 3.8 0.6 5.6 0.9 1.8 0.2 3.6 0.4 5.4 0.4s3.6-0.1 5.3-0.4c1.7-0.2 3.6-0.5 5.8-0.9-0.5 5.2-0.9 12.8-1.2 22.7s-0.6 20.8-0.9 32.8c-0.2 11.9-0.5 24.2-0.6 36.8-0.2 12.6-0.2 24.1-0.2 34.6 0 11.3 0.1 21.4 0.2 30.4 0.2 9 0.3 18.2 0.5 27.7-3.3-0.3-6.5-0.5-9.6-0.5-2.9 0-6.1 0.2-9.3 0.5l0.7-23.3h-0.6zm-69.7-32.4c0 9.8 1 17.9 3.1 24.3 2 6.4 4.7 11.5 7.9 15.3s6.8 6.5 10.9 8 8.1 2.2 12 2.2c6.4 0 11.9-1.6 16.4-4.8 4.6-3.2 8.4-7.4 11.4-12.5 3-5.2 5.2-11 6.6-17.5s2.1-13.1 2.1-19.6c0-15.7-3-27.7-9.1-35.9-6.1-8.3-15-12.4-26.7-12.4-5.9 0-11 1.3-15.3 4s-7.9 6.5-10.8 11.3-5 10.5-6.4 16.9c-1.4 6.5-2.1 13.4-2.1 20.7z" />
    <path d="m349.6 978.2c0-11.1-0.2-22-0.6-32.7s-0.9-21.6-1.4-32.7c4 0.3 8.1 0.6 12.2 0.9s8.2 0.4 12.2 0.4c2 0 4.5-0.1 7.3-0.3 2.9-0.2 5.8-0.3 8.7-0.4s5.8-0.2 8.5-0.4 5-0.3 6.8-0.3c2.5 0 5.6 0.1 9.3 0.3s7.6 0.7 11.7 1.5 8.2 2.1 12.2 3.8 7.7 4.1 11 7.3 5.9 7.2 8 12c2 4.8 3 10.7 3 17.8 0 11.1-2.4 20.1-7.2 27s-10.7 12.3-17.7 16.2-14.4 6.5-22.2 7.8-14.8 2-20.8 2c-3.9 0-7.4 0-10.6-0.1s-6.5-0.3-9.8-0.6v13.9c0 11.1 0.2 22 0.6 32.8s0.9 21.4 1.4 31.8c-3.9-0.3-8-0.5-12.4-0.5s-8.5 0.2-12.4 0.5c0.5-10.4 1-21 1.4-31.8s0.6-21.7 0.6-32.8v-43.4zm20.7 18.9c5.7 1.3 11.7 2 17.9 2 5.2 0 10.6-0.6 16.2-1.8s10.6-3.3 15.3-6.4c4.6-3.1 8.4-7.4 11.4-12.9 2.9-5.5 4.4-12.5 4.4-21.1 0-7.2-1-13.1-3-17.7s-4.8-8.1-8.5-10.6c-3.6-2.5-8-4.2-13.2-5.2-5.2-0.9-11.1-1.4-17.7-1.4h-22.7v75.1z" />
    <path d="m489.3 945.5c-0.4-10.7-0.9-21.6-1.4-32.7 2.2 0.3 4.3 0.6 6.3 0.9s4 0.4 6.1 0.4 4-0.1 6.1-0.4c2-0.3 4.1-0.5 6.3-0.9-0.5 11.1-1 22-1.4 32.7s-0.6 21.6-0.6 32.7c0 21.2 0.1 39.6 0.3 55.1 0.2 15.6 0.3 29.5 0.5 41.8 11.9 0 23.4-0.1 34.3-0.4s22.7-1.2 35.3-2.9c-0.8 3-1.3 5.6-1.3 7.6 0 1.9 0.4 4.1 1.3 6.8-7.7-0.3-15.5-0.5-23.2-0.5h-23.2-23.3c-7.5 0-15.3 0.2-23.3 0.5 0.5-10.4 1-21 1.4-31.8s0.6-21.7 0.6-32.8v-43.4c-0.2-11.1-0.4-22-0.8-32.7z" />
    <path d="m755.4 1086.2c-2.4-0.3-4.6-0.5-6.7-0.5h-6.7-6.7c-2.1 0-4.3 0.2-6.7 0.5-1.2-3.9-2.8-8.5-4.8-14s-4.2-11.2-6.7-17.2c-2.4-6-4.9-12-7.4-18.2-2.5-6.1-4.9-12-7.1-17.5-5.4-0.2-10.9-0.3-16.4-0.4-5.6-0.1-11.1-0.1-16.7-0.1-5.2 0-10.3 0-15.4 0.1-5 0.1-10.2 0.2-15.4 0.4-5.4 13.1-10.3 25.6-14.6 37.5-4.4 11.9-7.7 21.7-9.8 29.4-2.9-0.3-6-0.5-9.3-0.5-3.4 0-6.5 0.2-9.3 0.5 13.6-29.4 26.9-58.7 39.9-87.8s26-58.7 39.1-88.8h4.8c4 9.8 8.4 20.3 13.1 31.7s9.5 22.8 14.4 34.5c4.9 11.6 9.8 23.1 14.6 34.6 4.9 11.4 9.5 22.1 13.8 31.9s8.2 18.6 11.6 26.4c3.4 7.6 6.3 13.5 8.4 17.5zm-111.8-78.5c4.4 0 8.9 0.1 13.6 0.3s9.3 0.3 13.6 0.3c4.7 0 9.4 0 14.1-0.1s9.4-0.2 14.1-0.4l-28-64.1-27.4 64z" />
    <path d="m900.2 1080.3c-3.3 1.6-7.2 3-11.9 4.3-4.6 1.3-9.8 2.4-15.5 3.4s-11.7 1.5-17.9 1.5c-13.3 0-25.6-1.9-36.8-5.7-11.3-3.8-21-9.5-29.2-17.3-8.2-7.7-14.6-17.2-19.2-28.4s-6.9-23.9-6.9-38.2 2.3-27 6.9-38.2 11.1-20.7 19.4-28.4 18.3-13.6 29.9-17.7 24.4-6.1 38.4-6.1c11.1 0 21.2 1.1 30.4 3.4s17.3 5 24.4 8.2c-1.3 2.4-2.4 5.6-3.3 9.6-0.8 4-1.5 7.4-2 10.1l-1.5 0.5c-1.7-2-4-4.3-7.1-6.8-3-2.5-6.6-5-10.6-7.3-4-2.4-8.6-4.3-13.6-5.9s-10.6-2.4-16.7-2.4c-9.9 0-19.1 1.6-27.6 4.8s-15.9 8.1-22.2 14.8c-6.3 6.6-11.2 15.1-14.8 25.2-3.5 10.2-5.3 22.3-5.3 36.2 0 14 1.8 26 5.3 36.2s8.5 18.6 14.8 25.2 13.7 11.6 22.2 14.8 17.7 4.8 27.6 4.8c6.1 0 11.6-0.8 16.8-2.3 5.1-1.5 9.8-3.3 14.1-5.4s8.2-4.4 11.6-6.8 6.3-4.6 8.7-6.4l1 1-2.3 14.9c-1.4 1.3-3.8 2.8-7.1 4.4z" />
    <path d="m944.6 945.1c-0.4-10.8-0.9-21.5-1.4-32.3 7.6 0.3 15.1 0.6 22.6 0.9s15 0.4 22.6 0.4 15.1-0.1 22.5-0.4 14.9-0.5 22.5-0.9c-0.5 2.5-0.8 5-0.8 7.6 0 2.5 0.3 5 0.8 7.6-5.4-0.3-10.3-0.7-14.8-1s-9.2-0.7-14.1-1c-5-0.3-10.4-0.6-16.3-0.9s-13-0.4-21.2-0.4c-0.2 5.4-0.4 10.5-0.6 15.4-0.3 4.9-0.4 10.4-0.4 16.7 0 6.6 0 13 0.1 19.2s0.1 10.9 0.1 14.1c7.4 0 14-0.1 19.9-0.3s11.3-0.4 16.2-0.6c4.9-0.3 9.4-0.5 13.5-0.8s8-0.5 11.7-0.9c-0.5 2.5-0.8 5.1-0.8 7.8 0 2.5 0.3 5 0.8 7.6-4.4-0.5-9.5-0.9-15.3-1.3-5.8-0.3-11.6-0.5-17.4-0.6s-11.3-0.2-16.4-0.3-9.2-0.1-12.2-0.1c0 1.5 0 3.2-0.1 4.9-0.1 1.8-0.1 4-0.1 6.7v24.9c0 9.6 0 17.5 0.1 23.7s0.2 10.9 0.4 14.1h17.7c6.4 0 12.7-0.1 18.8-0.4s11.9-0.6 17.4-1 10.2-1 14.3-1.9c-0.3 1.2-0.5 2.5-0.6 3.9s-0.1 2.7-0.1 3.9c0 2.7 0.3 4.9 0.8 6.6-7.6-0.3-15.1-0.5-22.6-0.5h-22.8-23s-15.2 0.2-23 0.5c0.5-10.8 1-21.5 1.4-32.2s0.6-21.6 0.6-32.7v-43.4c-0.2-10.9-0.4-21.8-0.8-32.6z" />
  </svg>
);

export default ThirdPlace;
