import PropTypes from "prop-types";
import { computed, createModel } from "manikin-model";
import moment from "moment";
import { STATES } from "../constants/geography";

/**
 * A cache expiration for Events. Any event or list of events that has been
 * cached for more than this duration will be fetched the next time it is requested.
 */
export const CACHE_EXPIRATION = 10 * 60 * 1000; // 10 minutes

/**
 * An event where competitions took place.
 */
const Event = createModel("Event", {
  id: null,
  name: null,
  description: null,
  startDate: null,
  startDateMoment: computed(function() {
    return moment(this.get("startDate"), "YYYY-MM-DD");
  }),
  endDate: null,
  endDateMoment: computed(function() {
    return moment(this.get("endDate"), "YYYY-MM-DD");
  }),
  city: null,
  state: null,
  stateShort: computed(function() {
    const state = this.get("state");
    const stateObject = STATES.find(_state => _state.full === state);
    if (!stateObject) {
      return state;
    }
    return stateObject.short;
  }),
  region: null,
  fetchTime: null,
  isStale: computed(function() {
    const fetchTime = this.get("fetchTime");
    if (!fetchTime) {
      return true;
    }
    return new Date().getTime() - fetchTime > CACHE_EXPIRATION;
  })
});

Event.prototype.propTypes = {
  /** The ID of the event */
  id: PropTypes.string,
  /** A readable name representing the event */
  name: PropTypes.string,
  /** A short description of the event */
  description: PropTypes.string,
  /** The date when the event started, in the format YYYY-MM-DD */
  startDate: PropTypes.string.isRequired,
  /** The start date parsed by moment.js */
  startDateMoment: PropTypes.object,
  /** The date when the event ended, in the format YYYY-MM-DD */
  endDate: PropTypes.string.isRequired,
  /** The end date parsed by moment.js */
  endDateMoment: PropTypes.object,
  /** The city in which the event took place */
  city: PropTypes.string,
  /** The state in which the event took place */
  state: PropTypes.string,
  /** An abbrevated version of the state */
  stateShort: PropTypes.string,
  /** The AKA region in which the event took place */
  region: PropTypes.string,
  /** The new Date().getTime() from when the record was fetched */
  fetchTime: PropTypes.number,
  /** Indicates if the record is stale and needs to be refetched */
  isStale: PropTypes.bool
};

export default Event;

/**
 * Transforms raw event data into an Event
 * @param  {Object} rawEvent
 * @param  {Number} [fetchTime=currentTime] when the record was retrieved (ms)
 * @return {Event}
 */
export function transformEvent(rawEvent, fetchTime = new Date().getTime()) {
  return new Event({
    id: rawEvent.id.toString(),
    name: rawEvent.name,
    description: rawEvent.description,
    startDate: rawEvent.startDate,
    endDate: rawEvent.endDate,
    city: rawEvent.city,
    state: rawEvent.state,
    region: rawEvent.region,
    fetchTime
  });
}

export function getMockEvent(overrides = {}) {
  return new Event({
    id: "123",
    name: "WSIKF",
    description: "Washington State International Kite Festival",
    startDate: "2019-8-20",
    endDate: "2019-8-27",
    city: "Long Beach",
    state: "Washington",
    region: "10",
    fetchTime: new Date().getTime(),
    ...overrides
  });
}
