/**
 * US States/Territories/Whatever that are supported.
 */
export const STATES = [
  {
    full: "Alabama",
    short: "AL"
  },
  {
    full: "Alaska",
    short: "AK"
  },
  {
    full: "Arizona",
    short: "AZ"
  },
  {
    full: "Arkansas",
    short: "AR"
  },
  {
    full: "California",
    short: "CA"
  },
  {
    full: "Colorado",
    short: "CO"
  },
  {
    full: "Connecticut",
    short: "CT"
  },
  {
    full: "Delaware",
    short: "DE"
  },
  {
    full: "Florida",
    short: "FL"
  },
  {
    full: "Georgia",
    short: "GA"
  },
  {
    full: "Hawaii",
    short: "HI"
  },
  {
    full: "Idaho",
    short: "ID"
  },
  {
    full: "Illinois",
    short: "IL"
  },
  {
    full: "Indiana",
    short: "IN"
  },
  {
    full: "Iowa",
    short: "IA"
  },
  {
    full: "Kansas",
    short: "KS"
  },
  {
    full: "Kentucky",
    short: "KY"
  },
  {
    full: "Louisiana",
    short: "LA"
  },
  {
    full: "Maine",
    short: "ME"
  },
  {
    full: "Maryland",
    short: "MD"
  },
  {
    full: "Massachusetts",
    short: "MA"
  },
  {
    full: "Michigan",
    short: "MI"
  },
  {
    full: "Minnesota",
    short: "MN"
  },
  {
    full: "Mississippi",
    short: "MS"
  },
  {
    full: "Missouri",
    short: "MO"
  },
  {
    full: "Montana",
    short: "MT"
  },
  {
    full: "Nebraska",
    short: "NE"
  },
  {
    full: "Nevada",
    short: "NV"
  },
  {
    full: "New Hampshire",
    short: "NH"
  },
  {
    full: "New Jersey",
    short: "NJ"
  },
  {
    full: "New Mexico",
    short: "NM"
  },
  {
    full: "New York",
    short: "NY"
  },
  {
    full: "North Carolina",
    short: "NC"
  },
  {
    full: "North Dakota",
    short: "ND"
  },
  {
    full: "Ohio",
    short: "OH"
  },
  {
    full: "Oklahoma",
    short: "OK"
  },
  {
    full: "Oregon",
    short: "OR"
  },
  {
    full: "Pennsylvania",
    short: "PA"
  },
  {
    full: "Rhode Island",
    short: "RI"
  },
  {
    full: "South Carolina",
    short: "SC"
  },
  {
    full: "South Dakota",
    short: "SD"
  },
  {
    full: "Tennessee",
    short: "TN"
  },
  {
    full: "Texas",
    short: "TX"
  },
  {
    full: "Utah",
    short: "UT"
  },
  {
    full: "Vermont",
    short: "VT"
  },
  {
    full: "Virginia",
    short: "VA"
  },
  {
    full: "Washington",
    short: "WA"
  },
  {
    full: "West Virginia",
    short: "WV"
  },
  {
    full: "Wisconsin",
    short: "WI"
  },
  {
    full: "Wyoming",
    short: "WY"
  },
  {
    full: "District of Columbia",
    short: "DC"
  },
  {
    full: "Puerto Rico",
    short: "PR"
  },
  {
    full: "Guam",
    short: "GU"
  }
];

export const REGIONS = [
  {
    name: "1",
    description:
      "Connecticut, Maine, Massachusetts, New Hampshire, Upstate New York, Rhode Island, Vermont"
  },
  {
    name: "2",
    description: "New Jersey, Downstate New York, Pennsylvania"
  },
  {
    name: "3",
    description:
      "Delaware, District of Columbia, Maryland, Virginia, West Virginia"
  },
  {
    name: "4",
    description:
      "Alabama, Florida, Georgia, Kentucky, Mississippi, North Carolina, South Carolina, Tennessee, Puerto Rico"
  },
  {
    name: "5",
    description: "Michigan, Ohio"
  },
  {
    name: "6",
    description: "Illinois, Indiana, Iowa, Minnesota, Wisconsin"
  },
  {
    name: "7",
    description:
      "Colorado, Kansas, Missouri, Nebraska, North Dakota, South Dakota, Wyoming, Utah"
  },
  {
    name: "8",
    description: "Arkansas, Louisiana, New Mexico, Oklahoma, Texas"
  },
  {
    name: "9",
    description: "Idaho, Montana, Oregon"
  },
  {
    name: "10",
    description: "Alaska, Washington"
  },
  {
    name: "11",
    description: "N. California, Nevada"
  },
  {
    name: "12",
    description: "Arizona, S. California, Guam, Hawaii"
  },
  {
    name: "13",
    description: "International"
  }
];
