import showError from "../../utils/showError";
import showSuccess from "../../utils/showSuccess";

/**
 * Deletes the provided event from the server.
 * @param  {Event} event The event to be deleted. Must have an id.
 * @return {Promise<String>} The ID of the event that was deleted
 */
export default async function deleteEvent(event) {
  await this.axiosInstance.delete(`/events/${event.get("id")}`).catch(e => {
    showError(e.response.data.error);
    return Promise.reject(e);
  });

  showSuccess("The Event has been deleted.");

  return event.get("id");
}
