import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getTeam } from "../redux/modules/teams";
import Team from "../models/Team";
import { GET_TEAM } from "../redux/actions";

/**
 * A container that fetches and provides data about a single team
 */
export class TeamContainer extends React.Component {
  static propTypes = {
    /** A function called with data about the team */
    children: PropTypes.func.isRequired,
    /** A function to call to fetch the team from the API. Provided by Redux */
    onFetch: PropTypes.func.isRequired,
    /** The relevant team. Provided by Redux. */
    team: PropTypes.instanceOf(Team),
    /** The ID of the team to display */
    teamId: PropTypes.string.isRequired
  };

  state = {
    isLoading: true,
    errorCode: null,
    errorMessage: null
  };

  componentDidMount() {
    // Fetch the team if we don't have it when mounting
    if (!this.props.team) {
      this.fetchTeam();
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate(prevProps) {
    // If we change the team id and don't have that team, fetch it
    if (prevProps.teamId !== this.props.teamId && !this.props.team) {
      this.fetchTeam();
    }
  }

  /**
   * Fetches the team and updates state's isLoading, errorCode, and errorMessage.
   */
  fetchTeam = async () => {
    this.setState({
      isLoading: true,
      errorCode: null,
      errorMessage: null
    });
    await this.props.onFetch(this.props.teamId).catch(e => {
      this.setState({
        errorCode: e.status,
        errorMessage: e.data.error
      });
    });
    this.setState({ isLoading: false });
  };

  render() {
    return this.props.children({
      team: this.props.team,
      errorCode: this.state.errorCode,
      errorMessage: this.state.errorMessage,
      isLoading: this.state.isLoading,
      refresh: this.fetchTeam
    });
  }
}

const mapStateToProps = (state, props) => ({
  team: getTeam(state, props.teamId)
});

const mapDispatchToProps = {
  onFetch: GET_TEAM
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamContainer);
