import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  BlockListItem,
  TextButton,
  Icon,
  Input,
  Dropdown,
  Spacer
} from "react-watty-ui";
import TeamFindRequest from "../../models/findRequests/TeamFindRequest";
import TeamContainer from "../../containers/TeamContainer";
import TeamFieldDropdown from "./TeamFieldDropdown";
import NewTeamModal from "./NewTeamModal";

/**
 * A form field for selecting a Team.
 */
const TeamField = ({
  teamId,
  onChange,
  blurDebounce,
  searchDebounce,
  ...props
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const findRequest = new TeamFindRequest({
    nameContains: debouncedSearch,
    limit: 5
  });
  let closeDropdownTimeout;
  let searchTimeout;
  return (
    <React.Fragment>
      {teamId && (
        <TeamContainer key={teamId} teamId={teamId}>
          {({ team }) => (
            <BlockListItem>
              <TextButton
                as={Icon}
                data-test-id="deselect-team"
                icon="times"
                onClick={() => {
                  onChange(null);
                }}
              />{" "}
              {team.get("name")}
            </BlockListItem>
          )}
        </TeamContainer>
      )}
      <Spacer bottom="sm" as="div" />
      {!teamId && (
        <NewTeamModal
          teamName={searchValue}
          onCreate={team => onChange(team.get("id"))}
        >
          {({ open }) => (
            <Dropdown
              as="div"
              align="left"
              dropdownContent={({ components }) => (
                <TeamFieldDropdown
                  findRequest={findRequest}
                  dropdownComponents={components}
                  onChange={teamId => {
                    onChange(teamId);
                    setSearchValue("");
                  }}
                  onAddNew={open}
                />
              )}
            >
              {dropdown => (
                <Input
                  data-test-id="search"
                  value={searchValue}
                  onChange={e =>
                    new Promise(resolve => {
                      const value = e.target.value;
                      setSearchValue(value);
                      if (searchTimeout) {
                        window.clearTimeout(searchTimeout);
                      }
                      searchTimeout = window.setTimeout(() => {
                        setDebouncedSearch(value);
                        resolve();
                      }, searchDebounce);
                    })
                  }
                  placeholder="Type to search for teams"
                  onFocus={() => {
                    if (closeDropdownTimeout) {
                      window.clearTimeout(closeDropdownTimeout);
                    }
                    dropdown.actions.open();
                  }}
                  onBlur={() =>
                    new Promise(resolve => {
                      closeDropdownTimeout = window.setTimeout(() => {
                        dropdown.actions.close();
                        resolve();
                      }, blurDebounce);
                    })
                  }
                  {...props}
                />
              )}
            </Dropdown>
          )}
        </NewTeamModal>
      )}
    </React.Fragment>
  );
};

TeamField.defaultProps = {
  searchDebounce: 300,
  blurDebounce: 200
};

TeamField.propTypes = {
  /** A selected team id */
  teamId: PropTypes.string,
  /** A function called with the updated list of team ids when changed */
  onChange: PropTypes.func.isRequired,
  /** The amount of time to debounce search requests. Mainly used to speed up tests */
  searchDebounce: PropTypes.number,
  /** The amount of time to debounce closing the dropdown on blur. Mostly used to speed up tests. */
  blurDebounce: PropTypes.number
};

export default TeamField;
