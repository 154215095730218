import showError from "../../utils/showError";
import { transformTeam } from "../../models/Team";

/**
 * Fetches a team by ID
 * @param  {String} teamId The ID of the team to fetch
 * @return {Promise<Team>}
 */
export default async function getTeam(teamId) {
  const response = await this.axiosInstance.get(`/teams/${teamId}`).catch(e => {
    showError(e.response.data.error);
    return Promise.reject(e.response);
  });

  return transformTeam(response.data);
}
