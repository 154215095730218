import React from "react";
import PropTypes from "prop-types";
import { Loading, P, Spacer } from "react-watty-ui";
import CompetitorFindRequest from "../../models/findRequests/CompetitorFindRequest";
import CompetitorsContainer from "../../containers/CompetitorsContainer";

/**
 * The dropdown containing search results when selecting a competitor.
 */
const CompetitorsFieldDropdown = ({
  findRequest,
  dropdownComponents,
  onChange,
  onAddNew
}) => (
  <CompetitorsContainer findRequest={findRequest}>
    {({ competitors, isLoading }) => {
      if (isLoading) {
        return <Loading />;
      }
      if (!competitors.length) {
        return (
          <React.Fragment>
            <Spacer
              data-test-id="empty-state"
              as={P}
              top="sm"
              left="sm"
              bottom="sm"
              right="sm"
            >
              No competitors match your search.
            </Spacer>
            <dropdownComponents.Item onClick={onAddNew}>
              Create New Competitor
            </dropdownComponents.Item>
          </React.Fragment>
        );
      }
      return competitors.map(competitor => (
        <dropdownComponents.Item
          key={competitor.get("id")}
          data-test-id="competitor-option"
          onClick={() => onChange(competitor.get("id"))}
        >
          {competitor.get("name")} (akaId: {competitor.get("akaMemberId")})
        </dropdownComponents.Item>
      ));
    }}
  </CompetitorsContainer>
);

CompetitorsFieldDropdown.propTypes = {
  /** The find request for loading the competitor search results */
  findRequest: PropTypes.instanceOf(CompetitorFindRequest).isRequired,
  /** Components provided by the Dropdown component */
  dropdownComponents: PropTypes.shape({
    Item: PropTypes.any.isRequired
  }).isRequired,
  /** Called when an item is selected. Provided with the competitorId. */
  onChange: PropTypes.func.isRequired,
  /** Called when the user wants to create a new team */
  onAddNew: PropTypes.func.isRequired
};

export default CompetitorsFieldDropdown;
