import showError from "../../utils/showError";
import showSuccess from "../../utils/showSuccess";
import { transformTeam } from "../../models/Team";

/**
 * Saves a Team on the server.
 * @param {Team} team A team to save
 * @return {Promise<Team>} The team returned from the server after saving
 */
export default async function createTeam(team) {
  const response = await this.axiosInstance
    .post("/teams", team.getProperties("name", "description"))
    .catch(e => {
      showError(e.response.data.error);
      return Promise.reject(e);
    });

  showSuccess("A Team has been created.");

  // Build and return the team
  return transformTeam(response.data);
}
