export default function determineRankings(scores) {
  const sortedScores = scores.concat([]);
  // Sort from highest score to lowest, dropping invalid scores to the bottom
  sortedScores.sort((scoreA, scoreB) => {
    if (scoreB.get("isInvalid")) {
      return -1;
    }
    if (scoreA.get("isInvalid")) {
      return 1;
    }

    const difference = scoreB.get("score") - scoreA.get("score");

    // If the score is equal, sort by ID just to keep the sort order consistent.
    if (!difference) {
      if (scoreB.get("id") > scoreA.get("id")) {
        return -1;
      }
      if (scoreB.get("id") < scoreA.get("id")) {
        return 1;
      }
    }
    return difference;
  });

  // Determine who goes in what place.
  const rankings = sortedScores.reduce((accumulated, score) => {
    // If we have no rankings yet, put the first score in first place.
    if (!accumulated.length) {
      accumulated.push([score]);
      return accumulated;
    }

    // If this score is the same as the last ranking that we have set, then this
    // score is tied.
    const lastRanking = accumulated[accumulated.length - 1];
    if (lastRanking[0].get("score") === score.get("score")) {
      lastRanking.push(score);
      return accumulated;
    }

    // If this score is invalid and the last ranking is not, put this score in a new
    // rank at the end
    if (score.get("isInvalid") && !lastRanking[0].get("isInvalid")) {
      accumulated.push([score]);
      return accumulated;
    }

    // If the score is invalid and the last ranking was also invalid, group it
    // with the other invalid scores
    if (score.get("isInvalid")) {
      lastRanking.push(score);
      return accumulated;
    }

    // If none of the above conditions are met, push the score into the next rank.
    accumulated.push([score]);
    return accumulated;
  }, []);
  return rankings;
}
