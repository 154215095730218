import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { Spacer } from "react-watty-ui";
import CombineContainers from "../../containers/CombineContainers";
import CompetitionCreateContainer from "../../containers/CompetitionCreateContainer";
import UserContainer from "../../containers/UserContainer";
import ErrorPage from "../ErrorPage";
import PageWrapper from "../PageWrapper";
import NewCompetitionForm from "./NewCompetitionForm";
import NewCompetitionHeading from "./NewCompetitionHeading";

/**
 * The page wrapper for the competitions/new page.
 */
const NewCompetition = ({ match }) => {
  const eventId = match.params.eventId;
  return (
    <CombineContainers
      containers={[
        {
          component: UserContainer,
          reference: "userData"
        },
        {
          component: CompetitionCreateContainer,
          reference: "createData"
        }
      ]}
    >
      {({ userData, createData }) => {
        // Redirect to login if the user is not logged in.
        if (!userData.isLoggedIn) {
          return (
            <Redirect
              to={`/login?returnPath=/events/${eventId}/competitions/new`}
            />
          );
        }

        // Show 403 if the user is not an admin.
        if (!userData.user.get("isAdmin")) {
          return (
            <ErrorPage
              errorCode={403}
              errorMessage="You don't have permission to view this page."
            />
          );
        }
        return (
          <PageWrapper>
            <NewCompetitionHeading eventId={eventId} />
            <Spacer bottom="lg" as="div" />
            <NewCompetitionForm
              onSubmit={createData.saveCompetition}
              isDisabled={createData.isLoading}
              eventId={eventId}
            />
            {createData.savedCompetition && (
              <Redirect
                to={`/events/${eventId}/competitions/${createData.savedCompetition.get(
                  "id"
                )}`}
              />
            )}
          </PageWrapper>
        );
      }}
    </CombineContainers>
  );
};

NewCompetition.propTypes = {
  /**
   * Information provided by react router.
   */
  match: PropTypes.shape({
    params: PropTypes.shape({
      eventId: PropTypes.string.isRequired
    })
  })
};

export default NewCompetition;
