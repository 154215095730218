import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Tile, Spacer, H3, P, A } from "react-watty-ui";
import styled from "styled-components";
import Event from "../../models/Event";
import Competition from "../../models/Competition";
import Score from "../../models/Score";
import Team from "../../models/Team";
import Competitor from "../../models/Competitor";
import determineRankings from "../../utils/determineRankings";
import FirstPlace from "../illustrations/FirstPlace";
import SecondPlace from "../illustrations/SecondPlace";
import ThirdPlace from "../illustrations/ThirdPlace";

const WinnerContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTile = styled(Tile)`
  min-width: 250px;
  margin: 8px;
  padding: 8px;
`;

const Footer = styled(Spacer).attrs(() => ({
  top: "md",
  as: "div"
}))`
  text-align: center;
`;

/**
 * A display UI for showing a single competition on the Event page.
 */
const CompetitionTile = ({
  event,
  competition,
  scores,
  teams,
  competitors,
  ...props
}) => {
  let count = 0;
  const topThree = determineRankings(scores).filter(ranking => {
    if (count >= 3) {
      return false;
    }
    if (ranking[0].get("isInvalid")) {
      return false;
    }
    count += ranking.length;
    return true;
  });

  let ties = 0;
  return (
    <StyledTile {...props}>
      <H3>{competition.get("title")}</H3>
      <P>{competition.get("dateMoment").format("ddd MMM Do, YYYY")}</P>
      {!topThree.length && (
        <Spacer as={P} top="md" data-test-id="empty">
          No valid scores have been recorded.
        </Spacer>
      )}
      {topThree.map((place, index) => {
        const _ties = ties;
        ties += place.length - 1;
        const displayNames = place.map(score => {
          const competitorNames = score
            .get("competitorIds")
            .map(id => competitors[id].get("name"));

          const teamName = score.get("teamId")
            ? teams[score.get("teamId")].get("name")
            : null;

          const competitorName = competitorNames.join(", ") || null;

          return teamName || competitorName || "Unknown";
        });
        return (
          <React.Fragment key={place[0].get("id")}>
            {index > 0 && <hr />}
            <WinnerContainer data-test-id="score">
              {index === 0 && <FirstPlace width="100" height="100" />}
              {index + _ties === 1 && <SecondPlace width="100" height="50" />}
              {index + _ties === 2 && <ThirdPlace width="100" height="40" />}
              <Spacer right="md" />
              <div>
                {displayNames.map((name, _index) => (
                  <Spacer
                    key={name}
                    as={P}
                    data-test-id="name"
                    top={_index > 0 ? "md" : null}
                  >
                    {name}
                  </Spacer>
                ))}
              </div>
            </WinnerContainer>
          </React.Fragment>
        );
      })}
      <hr />
      <Footer>
        <A
          as={Link}
          to={`/events/${event.get("id")}/competitions/${competition.get(
            "id"
          )}`}
        >
          View Details
        </A>
      </Footer>
    </StyledTile>
  );
};

CompetitionTile.propTypes = {
  event: PropTypes.instanceOf(Event).isRequired,
  /** The competition to display */
  competition: PropTypes.instanceOf(Competition).isRequired,
  /** The scores for this competition */
  scores: PropTypes.arrayOf(PropTypes.instanceOf(Score)).isRequired,
  /** Teams related to this competition. Indexed by team id */
  teams: PropTypes.objectOf(PropTypes.instanceOf(Team)).isRequired,
  /** Competitors related to this competition. Indexed by competitor id */
  competitors: PropTypes.objectOf(PropTypes.instanceOf(Competitor)).isRequired
};

export default CompetitionTile;
